const INITIAL_STATE = {
    quantidadeBombas: 3,
    registro: {
        bomba: 1,
        horarioInicio: null,
        horarioTermino: null,
        domingo: false,
        segunda: false,
        terca: false,
        quarta: false,
        quinta: false,
        sexta: false,
        sabado: false,
        doseDiaria: 1,
        divisao: 1,
        ativo: false,
        umidadeAtual: 0,
        dosagemAutomatica: false,
        umidadeMinima: 10,
    },
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "DOSADORA_CONFIGURACAO_SET_REGISTRO":
            return { ...state, registro: action.payload.registro };

        case "DOSADORA_CONFIGURACAO_SET_QUANTIDADE_BOMBAS":
            return { ...state, quantidadeBombas: action.payload };

        default:
            return state;
    }
};
