import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import {
    Build as BuildIcon,
    Settings as SettingsIcon,
    Dashboard as DashboardIcon,
    WatchLater as WatchLaterIcon,
    LocalDrink as ReservoirIcon,
    Opacity as CalibrateIcon,
} from "@material-ui/icons";
import { setModoTela } from "../../main/mainActions";
import { desconectar } from "../../manual/manualActions";

const BottomMenu = (props) => {
    return props.appModoTela === "growbox" ? (
        <BottomNavigation
            showLabels
            value={props.modoTela}
            onChange={(event, value) => {
                if (props.modoTela == "manualGrowbox" && value != "manualGrowbox") {
                    props.desconectar();
                }
                props.setModoTela(value);
            }}
            style={{
                position: "fixed",
                right: 0,
                bottom: 0,
                left: 0,
                clear: "both",
                height: 80,
            }}
        >
            <BottomNavigationAction label="Dashboard" value="dashboardGrowbox" icon={<DashboardIcon />} />
            <BottomNavigationAction label="Data/Hora" value="dataHoraGrowbox" icon={<WatchLaterIcon />} />
            <BottomNavigationAction label="Configuração" value="configuracaoGrowbox" icon={<SettingsIcon />} />
            <BottomNavigationAction label="Manual" value="manualGrowbox" icon={<BuildIcon />} />
        </BottomNavigation>
    ) : (
        <BottomNavigation
            showLabels
            value={props.modoTela}
            onChange={(event, value) => {
                props.setModoTela(value);
            }}
            style={{
                position: "fixed",
                right: 0,
                bottom: 0,
                left: 0,
                clear: "both",
                height: 80,
            }}
        >
            <BottomNavigationAction label="Configuração" value="configuracaoDosadora" icon={<SettingsIcon />} />
            <BottomNavigationAction label="Dosagem Manual" value="manualDosadora" icon={<BuildIcon />} />
            <BottomNavigationAction label="Data/Hora" value="dataHoraDosadora" icon={<WatchLaterIcon />} />
            <BottomNavigationAction label="Reservatório" value="reservatorioDosadora" icon={<ReservoirIcon />} />
            <BottomNavigationAction label="Calibragem" value="calibragemDosadora" icon={<CalibrateIcon />} />
        </BottomNavigation>
    );
};

const mapStateToProps = (state) => ({
    appModoTela: state.app.appModoTela,
    modoTela: state.app.modoTela,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ setModoTela, desconectar }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(BottomMenu);
