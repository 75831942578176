import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Grid, Typography, Slider, makeStyles } from "@material-ui/core";

import { setModoTela } from "../main/mainActions";
import { initForm, getDados, salvar } from "./manualActions";
const useStyles = makeStyles((theme) => ({
    indicator: {
        backgroundColor: "#a1f5ec", // Cor personalizada para o indicador
    },
    thumb: {
        height: 20,
        width: 20,
    },
    track: {
        height: 10,
    },
    rail: {
        height: 10,
    },
}));
const ManualForm = (props) => {
    const classes = useStyles();

    useEffect(() => {
        props.getDados();
    }, []); // Executa apenas uma vez no montante

    const getLocalStyles = () => {
        return {
            root: {
                padding: 10,
                paddingBottom: 100,
            },
            gridInput: {
                marginTop: 16,
                paddingLeft: 16,
                paddingRight: 16,
            },
            gridButton: {
                marginTop: 20,
            },
        };
    };

    const style = getLocalStyles();

    return (
        <Grid container spacing={1} style={style.root} direction="column" justifyContent="center" alignItems="center">
            <Grid container direction="row" justifyContent="left" xs={12} sm={12} md={10} lg={8}>
                <Grid container style={style.gridInput} direction="column" justifyContent="left">
                    <Typography id="discrete-slider" gutterBottom variant="body1">
                        Vega (%)
                    </Typography>
                    <Slider
                        classes={{
                            thumb: classes.thumb,
                            track: classes.track,
                            rail: classes.rail,
                        }}
                        defaultValue={props.registro.canalUm || 0}
                        aria-labelledby="input-slider"
                        valueLabelDisplay="auto"
                        marks={[{ value: props.registro.canalUm || 0, label: `${props.registro.canalUm || 0} %` }]}
                        value={props.registro.canalUm || 0}
                        onChange={(event, value) => {
                            props.initForm({
                                ...props.registro,
                                canalUm: value || 0,
                            });
                            props.salvar({
                                ...props.registro,
                                canalUm: value || 0,
                            });
                        }}
                        step={1}
                        min={0}
                        max={100}
                    />
                </Grid>

                <Grid container style={style.gridInput} direction="column" justifyContent="left">
                    <Typography id="discrete-slider" gutterBottom variant="body1">
                        Flora (%)
                    </Typography>
                    <Slider
                        classes={{
                            thumb: classes.thumb,
                            track: classes.track,
                            rail: classes.rail,
                        }}
                        defaultValue={props.registro.canalDois || 0}
                        aria-labelledby="input-slider"
                        valueLabelDisplay="auto"
                        marks={[{ value: props.registro.canalDois || 0, label: `${props.registro.canalDois || 0} %` }]}
                        value={props.registro.canalDois || 0}
                        onChange={(event, value) => {
                            props.initForm({
                                ...props.registro,
                                canalDois: value || 0,
                            });
                            props.salvar({
                                ...props.registro,
                                canalDois: value || 0,
                            });
                        }}
                        step={1}
                        min={0}
                        max={100}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    sessao: state.auth.usuario,
    registro: state.manual.registro || {},
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ setModoTela, initForm, getDados, salvar }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ManualForm);
