const INITIAL_STATE = {
    registro: {
        dataHora: null,
    },
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "DOSADORA_DATAHORA_SET_REGISTRO":
            return { ...state, registro: action.payload.registro };

        case "DOSADORA_DATAHORA_LISTADO":
            if ((action.payload || {}).data) {
                let data = (action.payload || {}).data;
                let registro = {};

                //UTC
                let dataHora = data.datahora ? new Date((data.datahora + 3 * 3600) * 1000) : null;
                registro.dataHora = dataHora
                    ? `${dataHora.getFullYear()}/${String(dataHora.getMonth() + 1).padStart(2, "0")}/${String(dataHora.getDate()).padStart(2, "0")} ${String(
                          dataHora.getHours()
                      ).padStart(2, "0")}:${String(dataHora.getMinutes()).padStart(2, "0")}:${String(dataHora.getSeconds()).padStart(2, "0")}`
                    : "";

                return {
                    ...state,
                    registro,
                };
            }

            return state;

        default:
            return state;
    }
};
