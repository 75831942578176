const INITIAL_STATE = {
    quantidadeBombas: 3,
    tempoEspera: 10,
    tempoDosagem: null,
    registro: {
        bomba: 1,
        dose: 1,
    },
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "DOSADORA_MANUAL_SET_REGISTRO":
            return { ...state, registro: action.payload.registro };

        case "DOSADORA_MANUAL_SET_QUANTIDADE_BOMBAS":
            return { ...state, quantidadeBombas: action.payload };

        case "DOSADORA_MANUAL_SET_TEMPO_DOSAGEM":
            return { ...state, tempoDosagem: action.payload };

        default:
            return state;
    }
};
