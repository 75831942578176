import axios from "axios";
import consts from "../consts";
import { setError, setSuccess } from "../common/toast/toast";
import DateFormat from "../common/dateFormat/DateFormat";

export function initForm(registro = {}) {
    return {
        type: "DOSADORA_DATAHORA_SET_REGISTRO",
        payload: {
            registro: registro,
        },
    };
}

export function getDados(id_usuario) {
    return async (dispatch, getState) => {
        if (getState().dispositivo.dispositivoSelecionado) {
            axios
                .get(`${consts.API_URL}/dosadora/datahora?serie=${getState().dispositivo.dispositivoSelecionado.serie}`)
                .then((resp) => {
                    dispatch({
                        type: "DOSADORA_DATAHORA_LISTADO",
                        payload: resp,
                    });
                })
                .catch((e) => {
                    console.log(e);
                    setError("Controladora não respondeu");
                });
        }
    };
    /*
	{datahora: 1652369857}
*/
}

export function salvar(registro) {
    console.log(registro.dataHora && registro.dataHora.ts ? DateFormat.getTimestampParaDataHora(registro.dataHora.ts) : registro.dataHora);
    return (dispatch, getState) => {
        axios
            .post(`${consts.API_URL}/dosadora/datahora?serie=${getState().dispositivo.dispositivoSelecionado.serie}`, {
                ...registro,
                dataHora: registro.dataHora && registro.dataHora.ts ? DateFormat.getTimestampParaDataHora(registro.dataHora.ts) : registro.dataHora,
            })
            .then((resp) => {
                console.log(resp);
                setSuccess("Data/Hora atualizada com sucesso");
            })
            .catch((e) => {
                console.log(e);
                setError("Controladora não respondeu");
            });
    };
}
