const INITIAL_STATE = {
	registro: {
		canalUm: 0,
		canalDois: 0
	}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case 'MANUAL_SET_REGISTRO':
            return { ...state, registro: action.payload.registro };

		case 'MANUAL_LISTADO':

			if (action.payload.data) {
                let data = action.payload.data;
                let registro = {};

                registro.canalUm = data.canalUm ? Math.round((data.canalUm * 100) / 1023) : 0;
                registro.canalDois = data.canalDois ? Math.round((data.canalDois * 100) / 1023) : 0;

                return {
                    ...state,
                    registro,
                };
            }

			return state;


        default:
            return state;
    }
}
