const INITIAL_STATE = {
  modoTela: "dashboard",
  registro: {
    tempo: "",
    datahora: "",
    datahoraCultivo: "",
    temperatura: "",
    umidade: "",
    faseCultivo: "",
    canalUm: "",
    canalDois: "",
    atualizado: "",
    ordem: "",
    co2: "",
    wifi: "",
    ventStatus: false,
  },
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case "DASHBOARD_MODO_TELA":
      return { ...state, modoTela: action.payload.modo };

    case "DASHBOARD_LISTADO":
      if (action.payload.data) {
          let data = action.payload.data;
          let registro = {};

          if (data.tempo) {
              var s = data.tempo;
              var d = Math.floor(s / (24 * 60 * 60));
              s -= d * (24 * 60 * 60);
              var h = Math.floor(s / (60 * 60));
              s -= h * (60 * 60);
              var m = Math.floor(s / 60);

              registro.tempo = `${d}d, ${h}h, ${m}m`;
          }

          //UTC
          let dataHora = data.datahora ? new Date((data.datahora + 3 * 3600) * 1000) : null;
          registro.dataHora = dataHora
              ? `${String(dataHora.getDate()).padStart(2, "0")}/${String(dataHora.getMonth() + 1).padStart(2, "0")}/${dataHora.getFullYear()} ${String(
                    dataHora.getHours()
                ).padStart(2, "0")}:${String(dataHora.getMinutes()).padStart(2, "0")}`
              : "";

          //UTC
          let dataHoraCultivo = data.datahoraCultivo ? new Date((data.datahoraCultivo + 3 * 3600) * 1000) : null;
          registro.dataHoraCultivo = dataHoraCultivo
              ? `${String(dataHoraCultivo.getDate()).padStart(2, "0")}/${String(dataHoraCultivo.getMonth() + 1).padStart(2, "0")}/${dataHoraCultivo.getFullYear()} ${String(
                    dataHoraCultivo.getHours()
                ).padStart(2, "0")}:${String(dataHoraCultivo.getMinutes()).padStart(2, "0")}`
              : "";

          registro.temperatura = data.temperatura ? Math.round(data.temperatura, 1) : 0;

          if (data.temperatura == -1) {
              registro.temperatura = 28;
          }

          registro.umidade = data.umidade ? Math.round(data.umidade) : 0;
          if (registro.umidade <= 0) {
              registro.umidade = 40;
          }

          registro.faseCultivo = data.faseCultivo;

          registro.canalUm = data.canalUm ? Math.round((data.canalUm * 100) / 1023) : 0;
          registro.canalDois = data.canalDois ? Math.round((data.canalDois * 100) / 1023) : 0;

          registro.atualizado = data.atualizado ? data.atualizado : "Desatualizado";

          registro.ordem = data.ordemeprogresso;

          registro.co2 = data.co2;
          registro.wifi = data.RSSI ? (data.RSSI < -70 ? "Ruim" : data.RSSI <= -60 && data.RSSI >= -50 ? "Bom" : "Excelente") : "";
          registro.ventStatus = data.ventStatus;
          return {
              ...state,
              registro,
          };
      }

      return state;

    case "RESETAR_DASHBOARD":
      return INITIAL_STATE;

    default:
      return state;
  }
}
