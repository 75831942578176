import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DataHoraForm from "../dataHora/dataHoraForm";
import ConfiguracaoForm from "../configuracao/configuracaoForm";
import ManualForm from "../manual/manualForm";
import { Grid, FormLabel } from "@material-ui/core";
import {
    Spa as SpaIcon,
    WbSunny as WbSunnyIcon,
    DonutSmall as DonutSmallIcon,
    WatchLater as WatchLaterIcon,
    BubbleChart as BubbleChartIcon,
    SignalWifi0Bar as SemInternetIcon,
    CalendarToday as CalendarTodayIcon,
    EventAvailable as EventAvailableIcon,
    WbIncandescent as WbIncandescentIcon,
    SignalWifi3Bar as SinalBomInternetIcon,
    SignalWifi1Bar as SinalRuimInternetIcon,
    SignalWifi4Bar as SinalExcelenteInternetIcon,
} from "@material-ui/icons";

import imagemFlora from "../assets/images/flora.png";
import imagemVega from "../assets/images/vega.png";

import DashboardCard from "../common/components/dashboardCard";
import RadialChart from "../common/components/radialChart";
import { useEffect } from "react";
import { getDados } from "./dashboardActions";

function getLocalStyles() {
    return {
        root: {
            padding: 10,
            paddingBottom: 100,
        },
    };
}

const Dashboard = (props) => {
    useEffect(() => {
        props.getDados();
    }, []);
    useEffect(() => {
        if (props.modoTela === "dashboardGrowbox") {
            props.getDados();
        }
    }, [props.dispositivoSelecionado, props.modoTela]);
    const style = getLocalStyles();
    return (
        <>
            {props.modoTela == "dashboardGrowbox" ? (
                <Grid container spacing={1} style={style.root}>
                    <DashboardCard xs={6} xl={4} sm={4} md={3} lg={3} color="blue" icon={<WatchLaterIcon />} title="TEMPO ONLINE" subtitle={props.registro.tempo} />
                    <DashboardCard xs={6} xl={4} sm={4} md={3} lg={3} color="orange" icon={<CalendarTodayIcon />} title="DATE E HORA" subtitle={props.registro.dataHora} />
                    <DashboardCard
                        xs={6}
                        xl={4}
                        sm={4}
                        md={3}
                        lg={3}
                        color="darkGreen"
                        icon={<EventAvailableIcon />}
                        title="INÍCIO CULTIVO"
                        subtitle={props.registro.dataHoraCultivo}
                    />
                    <DashboardCard
                        xs={6}
                        xl={4}
                        sm={4}
                        md={3}
                        lg={3}
                        color="lightblue"
                        icon={<SpaIcon />}
                        title={`FASE: ${props.registro.faseCultivo == 1 ? "FLORA" : "VEGA"}`}
                        subtitle={`${props.registro.ordem}%`}
                    />
                    <DashboardCard
                        xs={6}
                        xl={4}
                        sm={4}
                        md={3}
                        lg={3}
                        color="green"
                        icon={<WbSunnyIcon />}
                        title="CLIMA"
                        subtitle={`${props.registro.temperatura}°C, ${props.registro.umidade}%`}
                    />
                    <DashboardCard
                        xs={6}
                        xl={4}
                        sm={4}
                        md={3}
                        lg={3}
                        color="yellow"
                        icon={
                            !props.registro.wifi ? (
                                <SemInternetIcon />
                            ) : props.registro.wifi == "Ruim" ? (
                                <SinalRuimInternetIcon />
                            ) : props.registro.wifi == "Bom" ? (
                                <SinalBomInternetIcon />
                            ) : (
                                <SinalExcelenteInternetIcon />
                            )
                        }
                        title="SINAL WI-FI"
                        subtitle={props.registro.wifi}
                    />
                    <DashboardCard
                        xs={12}
                        xl={6}
                        sm={6}
                        md={3}
                        lg={3}
                        color="red"
                        icon={<DonutSmallIcon />}
                        title="EXAUSTOR"
                        subtitle={props.registro.ventStatus ? "Ligado" : "Desligado"}
                    />
                    <DashboardCard
                        xs={12}
                        xl={6}
                        sm={6}
                        md={3}
                        lg={3}
                        color="lightGreen"
                        icon={<BubbleChartIcon />}
                        title="CONCENTRAÇÃO DE CO²"
                        subtitle={`${props.registro.co2}ppm`}
                    />
                    <DashboardCard
                        xs={12}
                        xl={12}
                        sm={12}
                        md={12}
                        lg={12}
                        color="purple"
                        icon={<WbIncandescentIcon />}
                        title="POTÊNCIA DOS LEDS"
                        component={
                            <>
                                <Grid container spacing={1} style={{ display: "flex", justifyContent: "center" }}>
                                    <RadialChart
                                        data={[
                                            {
                                                value: props.registro.canalUm,
                                                color: "#1565c0",
                                            },
                                            {
                                                value: props.registro.canalDois,
                                                color: "#e53935",
                                            },
                                        ]}
                                        dimension={window.innerWidth <= 400 ? window.innerWidth * 0.7 : 400}
                                        component={
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        fontSize: window.innerWidth <= 400 ? 20 : 24,
                                                        fontWeight: "bold",
                                                        color: "#1565c0",
                                                    }}
                                                >
                                                    {props.registro.canalUm}% Vega
                                                </div>
                                                <div
                                                    style={{
                                                        fontSize: window.innerWidth <= 400 ? 20 : 24,
                                                        fontWeight: "bold",
                                                        color: "#e53935",
                                                    }}
                                                >
                                                    {props.registro.canalDois}% Flora
                                                </div>
                                                <img
                                                    alt=""
                                                    src={props.registro.faseCultivo == 1 ? imagemFlora : imagemVega}
                                                    style={{
                                                        height: window.innerWidth <= 400 ? 80 : 100,
                                                        marginBottom: 10,
                                                    }}
                                                />
                                            </div>
                                        }
                                    />
                                </Grid>
                                <FormLabel style={{ width: "100%", textAlign: "right" }}>{props.registro.atualizado}</FormLabel>
                            </>
                        }
                    />
                </Grid>
            ) : props.modoTela == "dataHoraGrowbox" ? (
                <DataHoraForm />
            ) : props.modoTela == "configuracaoGrowbox" ? (
                <ConfiguracaoForm />
            ) : props.modoTela == "manualGrowbox" ? (
                <ManualForm />
            ) : null}
        </>
    );
};
const mapStateToProps = (state) => ({
    sessao: state.auth.usuario,
    modoTela: state.app.modoTela,
    registro: state.dashboard.registro,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ getDados }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
