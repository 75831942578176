import axios from "axios";
import consts from "../consts";
import { setError } from "../common/toast/toast";

export function setModoTela(modo) {
    return async (dispatch, getState) => {
        dispatch({
            type: "DASHBOARD_MODO_TELA",
            payload: {
                modo: modo,
            },
        });
    };
}

export function getDados() {
    return async (dispatch, getState) => {
        dispatch({
            type: "RESETAR_DASHBOARD",
        });
        if (getState().dispositivo.dispositivoSelecionado) {
            axios
                .get(`${consts.API_URL}/growbox/dashboard?serie=${getState().dispositivo.dispositivoSelecionado.serie}`)
                .then((resp) => {
                    dispatch({
                        type: "DASHBOARD_LISTADO",
                        payload: resp,
                    });
                })
                .catch((e) => {
                    console.log(e);
                    setError("Controladora não respondeu");
                });
        }
    };
    /*
	{
  "tempo": 14,
  "datahora": 1651675974,
  "datahoraCultivo": 1651640400,
  "temperatura": 23.58,
  "umidade": 0,
  "faseCultivo": 1,
  "canalUm": 1023,
  "canalDois": 1023,
  "atualizado": "14/11/2018",
  "ordemeprogresso": 0
}
*/
}
