const INITIAL_STATE = { registro: {}, modoTela: "lista", lista: [], dispositivoSelecionado: null, aguardando: false };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "DISPOSITIVO_MODO_TELA":
            return { ...state, modoTela: action.payload.modo, registro: action.payload.registro };

        case "DISPOSITIVO_SELECIONADO":
            return { ...state, dispositivoSelecionado: action.payload };

        case "DISPOSITIVO_LISTADO":
            return { ...state, lista: action.payload.data };

        case "DISPOSITIVO_SET_AGUARDANDO":
            return { ...state, aguardando: action.payload };

        default:
            return state;
    }
};
