import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AppBar, Tabs, Tab, Typography, Button, Grid, Slider, makeStyles, TextField } from "@material-ui/core";

import { setModoTela } from "../main/mainActions";
import { getQuantidadeBombas, setTempoDosagem, initForm, salvar } from "./manualDosadoraActions";
import { setSuccess } from "../common/toast/toast";
import Timer from "../common/components/timer";

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.primary.main,
        padding: 20,
    },
    indicator: {
        backgroundColor: "#a1f5ec", // Cor personalizada para o indicador
    },
    tab: {
        width: "80vw",
        outline: "none", // Remove o outline
        "&:focus": {
            outline: "none", // Remove o outline ao focar
        },
    },
    thumb: {
        height: 20,
        width: 20,
    },
    track: {
        height: 10,
    },
    rail: {
        height: 10,
    },
}));

const ManualFormDosadora = (props) => {
    const [exibirTimer, setExibirTimer] = useState(false);

    useEffect(() => {
        props.getQuantidadeBombas();
    }, []);

    useEffect(() => {
        if (props.tempoDosagem !== null) {
            setExibirTimer(true);
        }
    }, [props.tempoDosagem]);

    const classes = useStyles();

    function a11yProps(index) {
        return {
            id: `tab-${index}`,
            "aria-controls": `tab-panel-${index}`,
        };
    }

    const getLocalStyles = () => ({
        root: {
            padding: 10,
            paddingBottom: 100,
        },
        gridButton: {
            marginTop: 20,
        },
        gridInput: {
            padding: 20,
            marginTop: 20,
        },
    });

    const style = getLocalStyles();
    return (
        <Grid container xs={12} sm={12} md={12} lg={12} spacing={1} style={style.root} direction="column" justifyContent="center" alignItems="center">
            <Grid className={classes.tabs} item direction="row" justifyContent="left" xs={12} sm={12} md={10} lg={8}>
                <Typography variant="h4" className={classes.title}>
                    Dosagem Manual
                </Typography>
                <AppBar position="static">
                    <Tabs
                        variant="fullWidth"
                        classes={{
                            indicator: classes.indicator, // Aplicando a cor do indicador
                        }}
                        disabled={exibirTimer}
                        textColor="#FFF"
                        value={props.registro.bomba - 1}
                        onChange={(event, newValue) => {
                            props.initForm({
                                ...props.registro,
                                bomba: newValue + 1,
                            });
                        }}
                        aria-label="simple tabs example"
                    >
                        {Array.from({ length: props.quantidadeBombas }).map((_, i) => (
                            <Tab className={classes.tab} key={i} label={`Bomba ${i + 1}`} {...a11yProps(i)} />
                        ))}
                    </Tabs>
                </AppBar>
                {exibirTimer ? (
                    <Timer
                        mensagemEspera={`A dosagem iniciará em breve`}
                        segundosEspera={props.tempoEspera}
                        mensagemInicio={`Dosagem iniciada`}
                        segundosExecucao={props.tempoDosagem}
                        mensagemFim={`Dosagem Finalizada`}
                        finalizar={() => {
                            props.setTempoDosagem(null);
                            setExibirTimer(false);
                        }}
                    />
                ) : (
                    <Grid container style={style.gridInput} direction="row" justifyContent="center" alignItems="center">
                        <Typography gutterBottom variant="body1" style={{ marginRight: 10, width: "100%" }}>
                            Dose (ml)
                        </Typography>
                        <Slider
                            classes={{
                                thumb: classes.thumb,
                                track: classes.track,
                                rail: classes.rail,
                            }}
                            value={props.registro.dose}
                            valueLabelDisplay="auto"
                            disabled={false}
                            onChange={(event, value) => {
                                props.initForm({
                                    ...props.registro,
                                    dose: value,
                                });
                            }}
                            step={1}
                            min={1}
                            max={200}
                            style={{ maxWidth: "calc(100% - 130px)", marginBottom: 10 }}
                        />
                        <div style={{ width: 130, minWidth: "fit-content", justifyContent: "center", display: "flex", alignItems: "flex-end" }}>
                            {/* Input para edição direta */}
                            <TextField
                                type="number"
                                value={props.registro.dose || ""}
                                onChange={(event) => {
                                    const value = parseFloat(event.target.value); // Captura o valor digitado
                                    props.initForm({
                                        ...props.registro,
                                        dose: value,
                                    });
                                }}
                                onBlur={(event) => {
                                    let value = parseFloat(event.target.value);

                                    // Verifica se o valor está fora dos limites
                                    if (isNaN(value) || value < 1) {
                                        value = 1; // Define como 1 se for inválido ou menor que 1
                                    } else if (value > 200) {
                                        value = 200; // Define como 200 se for maior que 200
                                    }

                                    props.initForm({
                                        ...props.registro,
                                        dose: value,
                                    });
                                }}
                                inputProps={{
                                    min: 1,
                                    max: 200,
                                    step: 1,
                                }}
                            />
                            <span style={{ marginLeft: 5, fontSize: "1.1rem" }}>ml</span>
                        </div>
                    </Grid>
                )}
                <Grid container style={style.gridButton} direction="row" justifyContent="center">
                    <Button
                        style={{ marginRight: 5 }}
                        disabled={exibirTimer}
                        variant="outlined"
                        onClick={() => {
                            props.salvar(props.registro);
                        }}
                        color="primary"
                    >
                        Dosar
                    </Button>
                    <Button style={{ marginLeft: 5 }} disabled={exibirTimer} variant="outlined" onClick={() => props.setModoTela("configuracaoDosadora")} color="secondary">
                        Voltar
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    sessao: state.auth.usuario,
    modoTela: state.app.modoTela,
    registro: state.manualDosadora.registro,
    tempoEspera: state.manualDosadora.tempoEspera,
    tempoDosagem: state.manualDosadora.tempoDosagem,
    quantidadeBombas: state.manualDosadora.quantidadeBombas,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ setModoTela, initForm, setSuccess, salvar, getQuantidadeBombas, setTempoDosagem }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ManualFormDosadora);
