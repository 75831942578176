import axios from "axios";
import consts from "../consts";
import { setErroAPI } from "../common/toast/toast";

export function initForm(registro = {}) {
    return {
        type: "DOSADORA_MANUAL_SET_REGISTRO",
        payload: {
            registro,
        },
    };
}

export function getQuantidadeBombas() {
    return async (dispatch, getState) => {
        const resposta = await axios.get(`${consts.API_URL}/dosadora/bombas?serie=${getState().dispositivo.dispositivoSelecionado.serie}`);
        const { quantidade_bombas } = resposta.data;
        dispatch({
            type: "DOSADORA_MANUAL_SET_QUANTIDADE_BOMBAS",
            payload: quantidade_bombas || 3,
        });
    };
}

export function salvar(registro) {
    return async (dispatch, getState) => {
        axios
            .post(`${consts.API_URL}/dosadora/manual?serie=${getState().dispositivo.dispositivoSelecionado.serie}`, {
                ...registro,
            })
            .then((resp) => {
                let tempoDosagem = parseInt((resp.data || {}).aguarde) || 0;
                tempoDosagem = tempoDosagem < 10 ? 0 : tempoDosagem - 10;
                dispatch(setTempoDosagem(tempoDosagem));
            })
            .catch((e) => {
                console.log(e);
                setErroAPI(e);
            });
    };
}

export function setTempoDosagem(tempoDosagem) {
    return {
        type: "DOSADORA_MANUAL_SET_TEMPO_DOSAGEM",
        payload: tempoDosagem,
    };
}
