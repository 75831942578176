import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

import mainReducer from "./mainReducer";
import AuthReducer from "../auth/authReducer";
import TabReducer from "../common/tab/tabReducer";
import ManualReducer from "../manual/manualReducer";
import UsuarioReducer from "../usuario/usuarioReducer";
import DataHoraReducer from "../dataHora/dataHoraReducer";
import DashboardReducer from "../dashboard/dashboardReducer";
import DispositivoReducer from "../dispositivo/dispositivoReducer";
import ConfiguracaoReducer from "../configuracao/configuracaoReducer";
import ManualDosadoraReducer from "../manualDosadora/manualDosadoraReducer";
import DataHoraDosadoraReducer from "../dataHoraDosadora/dataHoraDosadoraReducer";
import CalibragemDosadoraReducer from "../calibragemDosadora/calibragemDosadoraReducer";
import DispositivoUsuarioReducer from "../dispositivoUsuario/dispositivoUsuarioReducer";
import ConfiguracaoDosadoraReducer from "../configuracaoDosadora/configuracaoDosadoraReducer";
import ReservatorioDosadoraReducer from "../reservatorioDosadora/reservatorioDosadoraReducer";

const rootReducer = combineReducers({
    tab: TabReducer,
    app: mainReducer,
    form: formReducer,
    auth: AuthReducer,
    toastr: toastrReducer,
    manual: ManualReducer,
    usuario: UsuarioReducer,
    dataHora: DataHoraReducer,
    dashboard: DashboardReducer,
    dispositivo: DispositivoReducer,
    configuracao: ConfiguracaoReducer,
    manualDosadora: ManualDosadoraReducer,
    dataHoraDosadora: DataHoraDosadoraReducer,
    calibragemDosadora: CalibragemDosadoraReducer,
    dispositivoUsuario: DispositivoUsuarioReducer,
    configuracaoDosadora: ConfiguracaoDosadoraReducer,
    reservatorioDosadora: ReservatorioDosadoraReducer,
});

export default rootReducer;
