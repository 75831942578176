import DateFormat from '../common/dateFormat/DateFormat';

const INITIAL_STATE = {
    registro: {
        ligarHora: null,
        desligarHora: null,
        diasVega: 0,
        diasFlora: 0,
        canalUm: 0,
        canalDois: 0,
        avisoAgua: 1,
        tempVento: 0,
        ventilacaoConstante: false,
        umidadeMinima: 1,
        umidadeMaxima: 1,
    },
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "CONFIGURACAO_SET_REGISTRO":
            return { ...state, registro: action.payload.registro };

        case "CONFIGURACAO_LISTADO":
            if (action.payload.data) {
                let data = action.payload.data;
                let registro = {};
                registro.ligarHora = data.ligarHora
                    ? `${DateFormat.getDataAtual()} ${data.ligarHora
                          .split(":")
                          .map((valor) => `${valor.length == 1 ? "0" : ""}${valor}`)
                          .join(":")}:00`
                    : null;
                registro.desligarHora = data.desligarHora
                    ? `${DateFormat.getDataAtual()} ${data.desligarHora
                          .split(":")
                          .map((valor) => `${valor.length == 1 ? "0" : ""}${valor}`)
                          .join(":")}:00`
                    : null;
                registro.diasVega = data.diasVega;
                registro.diasFlora = data.diasFlora;
                registro.canalUm = data.canalUm ? Math.round((data.canalUm * 100) / 1023) : 0;
                registro.canalDois = data.canalDois ? Math.round((data.canalDois * 100) / 1023) : 0;
                registro.avisoAgua = data.avisoAgua;
                registro.tempVento = data.tempVento;
                registro.ventilacaoConstante = data.ventilacaoConstante;
                registro.umidadeMinima = data.umidadeMinima;
                registro.umidadeMaxima = data.umidadeMaxima;
                return {
                    ...state,
                    registro,
                };
            }
            return state;

        default:
            return state;
    }
};
