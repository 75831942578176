import axios from "axios";
import consts from "../consts";
import { setErroAPI, setError, setSuccess } from "../common/toast/toast";
import DateFormat from "../common/dateFormat/DateFormat";

export function initForm(registro = {}) {
    return {
        type: "DOSADORA_CONFIGURACAO_SET_REGISTRO",
        payload: {
            registro,
        },
    };
}

export function getQuantidadeBombas() {
    return async (dispatch, getState) => {
        const resposta = await axios.get(`${consts.API_URL}/dosadora/bombas?serie=${getState().dispositivo.dispositivoSelecionado.serie}`);
        const { quantidade_bombas } = resposta.data;
        dispatch({
            type: "DOSADORA_CONFIGURACAO_SET_QUANTIDADE_BOMBAS",
            payload: quantidade_bombas || 3,
        });
    };
}

export function getDados() {
    return async (dispatch, getState) => {
        if (!getState().dispositivo.dispositivoSelecionado) return {};
        try {
            dispatch(getQuantidadeBombas());
            const bombaSelecionada = getState().configuracaoDosadora.registro.bomba;

            const resposta = await axios.get(`${consts.API_URL}/dosadora/configuracao?serie=${getState().dispositivo.dispositivoSelecionado.serie}`);
            if (!resposta.data) {
                setError("Não foi possível buscar dados da dosadora");
                return {};
            }
            const dadosTotasBombas = resposta.data || {};
            const registro = {
                bomba: bombaSelecionada,
                horarioInicio: dadosTotasBombas[`horaInicio${bombaSelecionada}`]
                    ? `${DateFormat.getDataAtual()} ${dadosTotasBombas[`horaInicio${bombaSelecionada}`]
                          .split(":")
                          .map((valor) => `${valor.length == 1 ? "0" : ""}${valor}`)
                          .join(":")}:00`
                    : null,
                horarioTermino: dadosTotasBombas[`horaTermino${bombaSelecionada}`]
                    ? `${DateFormat.getDataAtual()} ${dadosTotasBombas[`horaTermino${bombaSelecionada}`]
                          .split(":")
                          .map((valor) => `${valor.length == 1 ? "0" : ""}${valor}`)
                          .join(":")}:00`
                    : null,
                domingo: dadosTotasBombas[`domingo${bombaSelecionada}`] || false,
                segunda: dadosTotasBombas[`segunda${bombaSelecionada}`] || false,
                terca: dadosTotasBombas[`terca${bombaSelecionada}`] || false,
                quarta: dadosTotasBombas[`quarta${bombaSelecionada}`] || false,
                quinta: dadosTotasBombas[`quinta${bombaSelecionada}`] || false,
                sexta: dadosTotasBombas[`sexta${bombaSelecionada}`] || false,
                sabado: dadosTotasBombas[`sabado${bombaSelecionada}`] || false,
                doseDiaria: dadosTotasBombas[`doseDiaria${bombaSelecionada}`] || 1,
                divisao: dadosTotasBombas[`divisao${bombaSelecionada}`] || 1,
                ativo: dadosTotasBombas[`ativo${bombaSelecionada}`] || false,
                umidadeAtual: dadosTotasBombas[`umidadeAtual${bombaSelecionada}`] || 0,
                dosagemAutomatica: dadosTotasBombas[`dosagemAutomatica${bombaSelecionada}`] || false,
                umidadeMinima: dadosTotasBombas[`umidadeMinima${bombaSelecionada}`] || 10,
            };
            dispatch(initForm(registro));
        } catch (e) {
            console.log(e);
            setError("Controladora não respondeu");
        }
    };
    /*
	{
		{
    "horaInicio1": "01:10",
    "horaTermino1": "13:11",
    "domingo1": true,
    "segunda1": false,
    "terca1": true,
    "quarta1": false,
    "quinta1": true,
    "sexta1": false,
    "sabado1": true,
    "doseDiaria1": 101,
    "divisao1": 11,
    "ativo1": true,
    "horaInicio2": "02:20",
    "horaTermino2": "14:22",
    "domingo2": true,
    "segunda2": false,
    "terca2": false,
    "quarta2": false,
    "quinta2": false,
    "sexta2": false,
    "sabado2": false,
    "doseDiaria2": 102,
    "divisao2": 12,
    "ativo2": false,
    "horaInicio3": "03:30",
    "horaTermino3": "15:33",
    "domingo3": true,
    "segunda3": false,
    "terca3": false,
    "quarta3": false,
    "quinta3": false,
    "sexta3": false,
    "sabado3": false,
    "doseDiaria3": 103,
    "divisao3": 13,
    "ativo3": false
}
	}
	*/
}

export function salvar(registro) {
    return (dispatch, getState) => {
        axios
            .post(`${consts.API_URL}/dosadora/configuracao?serie=${getState().dispositivo.dispositivoSelecionado.serie}`, {
                ...registro,
                horarioInicio:
                    registro.horarioInicio && registro.horarioInicio.ts //quando foi editado o horario no front aparece esse campo ts, senão fica no formato  YYYY/MM/DD HH:mm:SS
                        ? `${new Date(DateFormat.getTimestampParaDataHora(registro.horarioInicio.ts)).getHours()}:${new Date(
                              DateFormat.getTimestampParaDataHora(registro.horarioInicio.ts)
                          ).getMinutes()}`
                        : `${new Date(registro.horarioInicio).getHours()}:${new Date(registro.horarioInicio).getMinutes()}`,
                horarioTermino:
                    registro.horarioTermino && registro.horarioTermino.ts
                        ? `${new Date(DateFormat.getTimestampParaDataHora(registro.horarioTermino.ts)).getHours()}:${new Date(
                              DateFormat.getTimestampParaDataHora(registro.horarioTermino.ts)
                          ).getMinutes()}`
                        : `${new Date(registro.horarioTermino).getHours()}:${new Date(registro.horarioTermino).getMinutes()}`,
            })
            .then((resp) => {
                // console.log(resp);
                setSuccess("Configuração atualizada com sucesso");
            })
            .catch((e) => {
                console.log(e);
                setErroAPI(e);
            });
    };
}
