import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Typography, Button, Grid, Slider, makeStyles, Select, MenuItem, InputLabel, FormControl, TextField } from "@material-ui/core";

import { setModoTela } from "../main/mainActions";
import { getQuantidadeBombas, setTempoDosagem, initForm, salvar, buscarUltimaCalibragem } from "./calibragemDosadoraActions";
import { setSuccess } from "../common/toast/toast";
import Timer from "../common/components/timer";

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.primary.main,
        padding: 20,
    },
    indicator: {
        backgroundColor: "#a1f5ec", // Cor personalizada para o indicador
    },
    text: {
        fontSize: "1.2rem",
    },
    tab: {
        width: "80vw",
        outline: "none", // Remove o outline
        "&:focus": {
            outline: "none", // Remove o outline ao focar
        },
    },
    thumb: {
        height: 20,
        width: 20,
    },
    track: {
        height: 10,
    },
    rail: {
        height: 10,
    },
}));

const CalibragemFormDosadora = (props) => {
    const [etapa, setEtapa] = useState(1);
    const [exibirTimer, setExibirTimer] = useState(false);

    useEffect(() => {
        props.getQuantidadeBombas();
        props.buscarUltimaCalibragem();
    }, []);

    useEffect(() => {
        if (props.tempoDosagem > 0) {
            setExibirTimer(true);
        }
    }, [props.tempoDosagem]);

    const classes = useStyles();

    const getLocalStyles = () => ({
        root: {
            padding: 10,
            paddingBottom: 100,
        },
        gridButton: {
            marginTop: 20,
        },
        gridInput: {
            padding: 20,
            marginTop: 20,
        },
    });

    const style = getLocalStyles();

    const dataUltimaCalibragem = props.ultimaCalibragem[`dataHora${props.registro.bomba}`]
        ? new Date(props.ultimaCalibragem[`dataHora${props.registro.bomba}`] * 1000).toLocaleDateString("pt-BR", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
          })
        : "Indefinido";

    const doseUltimaCalibragem =
        props.ultimaCalibragem[`calib${props.registro.bomba}`] >= 0 ? String(props.ultimaCalibragem[`calib${props.registro.bomba}`]).replace(".", ",") : "Indefinido";

    const ultimaCalibragemEmBranco = !Object.keys(props.ultimaCalibragem).length;
    return (
        <Grid container xs={12} sm={12} md={12} lg={12} spacing={1} style={style.root} direction="column" justifyContent="center" alignItems="center">
            <Grid container justifyContent="left" xs={12} sm={12} md={10} lg={8}>
                <Typography variant="h4" className={classes.title}>
                    Calibragem
                </Typography>
            </Grid>

            {etapa == 1 ? (
                <Grid container xs={8} sm={6} md={4} lg={2} style={{ paddingTop: 200 }}>
                    <FormControl fullWidth>
                        {props.aguardando ? (
                            <Typography variant="h6" className={classes.title} align="center">
                                Buscando última calibragem...
                            </Typography>
                        ) : ultimaCalibragemEmBranco ? (
                            <Typography variant="h6" className={classes.title} align="center">
                                Última calibragem não encontrada
                            </Typography>
                        ) : (
                            <>
                                <InputLabel id="bomba-calibragem">Selecione a Bomba</InputLabel>
                                <Select
                                    xs={12}
                                    labelId="bomba-calibragem"
                                    id="bomba-calibragem"
                                    value={props.registro.bomba}
                                    label="Selecione a Bomba"
                                    onChange={(event) => {
                                        props.initForm({
                                            ...props.registro,
                                            bomba: event.target.value,
                                        });
                                        props.getDados();
                                    }}
                                >
                                    {Array.from({ length: props.quantidadeBombas }).map((_, i) => (
                                        <MenuItem key={i} value={i + 1}>
                                            Bomba {i + 1}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </>
                        )}
                    </FormControl>
                </Grid>
            ) : etapa == 2 ? (
                <Grid container direction="column" xs={8} sm={6} style={{ paddingTop: 200, gap: 10 }}>
                    <Typography variant="subtitle" align="center" className={classes.text}>
                        Valor da última calibração foi de {doseUltimaCalibragem} ml/min em {dataUltimaCalibragem}
                    </Typography>
                    <Typography variant="h6" className={classes.title} align="center">
                        Faça a dosagem na proveta!
                    </Typography>
                </Grid>
            ) : null}

            {etapa == 3 && exibirTimer ? (
                <Timer
                    mensagemEspera={`A dosagem iniciará em breve`}
                    segundosEspera={props.tempoEspera}
                    mensagemInicio={`Dosagem iniciada`}
                    segundosExecucao={props.tempoDosagem}
                    mensagemFim={`Dosagem Finalizada`}
                    finalizar={() => {
                        props.setTempoDosagem(0);
                        setExibirTimer(false);
                        setEtapa(etapa + 1);
                    }}
                />
            ) : null}

            {etapa == 4 ? (
                <Grid container style={style.gridInput} xs={12} sm={12} md={10} lg={8} direction="row" justifyContent="center" alignItems="center">
                    <Typography gutterBottom variant="body1" style={{ marginRight: 10, width: "100%" }}>
                        Informe a quantidade dosada (ml)
                    </Typography>
                    <Slider
                        classes={{
                            thumb: classes.thumb,
                            track: classes.track,
                            rail: classes.rail,
                        }}
                        value={props.registro.dose}
                        valueLabelDisplay="auto"
                        disabled={false}
                        onChange={(event, value) => {
                            props.initForm({
                                ...props.registro,
                                dose: value,
                            });
                        }}
                        step={0.1}
                        min={10.1}
                        max={200}
                        style={{ maxWidth: "calc(100% - 130px)", marginBottom: 10 }}
                    />
                    <div style={{ width: 130, minWidth: "fit-content", justifyContent: "center", display: "flex", alignItems: "flex-end" }}>
                        {/* Input para edição direta */}
                        <TextField
                            type="number"
                            value={props.registro.dose}
                            onChange={(event) => {
                                const value = parseFloat(event.target.value); // Captura o valor digitado
                                props.initForm({
                                    ...props.registro,
                                    dose: value,
                                });
                            }}
                            onBlur={(event) => {
                                let value = parseFloat(event.target.value);

                                // Verifica se o valor está fora dos limites
                                if (isNaN(value) || value < 10.1) {
                                    value = 10.1; // Define como 1 se for inválido ou menor que 1
                                } else if (value > 200) {
                                    value = 200; // Define como 200 se for maior que 200
                                }

                                props.initForm({
                                    ...props.registro,
                                    dose: value,
                                });
                            }}
                            inputProps={{
                                min: 10.1,
                                max: 200,
                                step: 0.1,
                            }}
                        />
                        <span style={{ marginLeft: 5, fontSize: "1.1rem" }}>ml</span>
                    </div>
                </Grid>
            ) : null}

            {ultimaCalibragemEmBranco || (etapa == 3 && !props.tempoDosagem) ? null : (
                <Grid container style={style.gridButton} direction="row" justifyContent="center">
                    <Button
                        style={{ marginRight: 5 }}
                        disabled={exibirTimer}
                        variant="outlined"
                        onClick={() => {
                            if ([1, 2, 3].includes(etapa)) {
                                setEtapa(etapa + 1);
                            }
                            if (etapa == 2) {
                                props.salvar({ bomba: props.registro.bomba, dosar: true });
                            }
                            if (etapa == 4) {
                                props.salvar({ bomba: props.registro.bomba, dose: props.registro.dose });
                                setTimeout(() => {
                                    setEtapa(1);
                                }, 2000);
                            }
                        }}
                        color="primary"
                    >
                        {etapa == 1 ? "Avançar" : etapa == 2 ? "Executar Calibração" : "Salvar"}
                    </Button>
                    <Button
                        style={{ marginLeft: 5 }}
                        disabled={exibirTimer}
                        variant="outlined"
                        onClick={() => {
                            etapa == 1 ? props.setModoTela("configuracaoDosadora") : setEtapa(etapa - 1);
                        }}
                        color="secondary"
                    >
                        Voltar
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    sessao: state.auth.usuario,
    modoTela: state.app.modoTela,
    registro: state.calibragemDosadora.registro,
    tempoEspera: state.calibragemDosadora.tempoEspera,
    tempoDosagem: state.calibragemDosadora.tempoDosagem,
    quantidadeBombas: state.calibragemDosadora.quantidadeBombas,
    ultimaCalibragem: state.calibragemDosadora.ultimaCalibragem,
    aguardando: state.calibragemDosadora.aguardando,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ setModoTela, initForm, setSuccess, salvar, getQuantidadeBombas, setTempoDosagem, buscarUltimaCalibragem }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(CalibragemFormDosadora);
