const INITIAL_STATE = {
    quantidadeBombas: 3,
    tempoEspera: 10,
    tempoDosagem: 0,
    aguardando: false,
    ultimaCalibragem: {},
    registro: {
        bomba: 1,
        dose: 10.1,
    },
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "DOSADORA_CALIBRAGEM_SET_REGISTRO":
            return { ...state, registro: action.payload.registro };

        case "DOSADORA_CALIBRAGEM_SET_QUANTIDADE_BOMBAS":
            return { ...state, quantidadeBombas: action.payload };

        case "DOSADORA_CALIBRAGEM_SET_TEMPO_DOSAGEM":
            return { ...state, tempoDosagem: action.payload };

        case "DOSADORA_CALIBRAGEM_SET_ULTIMA_CALIBRAGEM":
            return { ...state, ultimaCalibragem: action.payload };

        case "DOSADORA_CALIBRAGEM_SET_AGUARDANDO":
            return { ...state, aguardando: action.payload };

        default:
            return state;
    }
};
