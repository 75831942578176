import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import Main from "./main";
import UsuarioAlterarSenha from "../usuario/usuarioAlterarSenha";
import DispositivoUsuarioNovo from '../dispositivoUsuario/dispositivoUsuarioNovo';

export default (props) => (
    <div style={{ paddingTop: 64 }}>
        <Switch>
            <Route exact path="/" component={Main} />
            <Route path="/dispositivoUsuarioNovo" component={DispositivoUsuarioNovo} />
            <Route path="/usuarioAlterarSenha" component={UsuarioAlterarSenha} />
            <Redirect from="*" to="/" />
        </Switch>
    </div>
);
