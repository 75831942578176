import React, { useState, useEffect } from "react";
import { Typography, makeStyles } from "@material-ui/core";

import HourGlass from "../../assets/images/hourglass.gif";
import GreenEngine from "../../assets/images/green-engine.gif";
import "./timer.css";

import { CheckCircleOutlineRounded } from "@material-ui/icons";

export default function Timer(props) {
    const useStyles = makeStyles((theme) => ({
        title: {
            display: "flex",
            alignItems: "center",
            color: theme.palette.primary.main,
            paddingTop: 50,
        },
        subtitle: {
            display: "flex",
            alignItems: "center",
            color: theme.palette.primary.main,
            paddingTop: 20,
            paddingBottom: 50,
        },
        segundos: {
            minWidth: 50,
            textAlign: "center",
        },
    }));

    const classes = useStyles();

    const [mensagemEspera, setMensagemEspera] = useState(props.mensagemEspera);
    const [segundosEspera, setSegundosEspera] = useState(props.segundosEspera);
    const [mensagemInicio, setMensagemInicio] = useState(props.mensagemInicio);
    const [segundosExecucao, setSegundosExecucao] = useState(props.segundosExecucao);
    const [mensagemFim, setMensagemFim] = useState(props.mensagemFim);


    useEffect(() => {
        if (segundosEspera > 0) {
            const intervaloEspera = setInterval(() => {
                setSegundosEspera((prev) => prev - 1);
            }, 1000);

            // Limpa o intervalo quando o tempo de espera acaba
            return () => clearInterval(intervaloEspera);
        } else {
            setMensagemEspera(""); // Limpa a mensagem de espera
        }
    }, [segundosEspera]);

    useEffect(() => {

        if (!mensagemEspera) {
            if (segundosExecucao === 0) {
                setMensagemInicio(""); // Limpa a mensagem de início
            } else if (segundosExecucao > 0) {
                const intervaloExecucao = setInterval(() => {
                    setSegundosExecucao((prev) => prev - 1);
                }, 1000);

                // Limpa o intervalo quando o tempo de execução acaba
                return () => clearInterval(intervaloExecucao);
            }
        } 
       
    }, [mensagemEspera, segundosExecucao]);

    useEffect(() => {
        if (!mensagemInicio) {
            setTimeout(props.finalizar, 2000);
        }
    }, [mensagemInicio]);

    return mensagemEspera ? (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <Typography variant="h4" className={classes.title}>
                {mensagemEspera}
            </Typography>
            <Typography variant="h5" className={classes.subtitle}>
                Restam <div className={classes.segundos}>{segundosEspera}</div> segundos <img className="img-timer" src={HourGlass} width={50} alt={mensagemEspera}></img>
            </Typography>
        </div>
    ) : mensagemInicio ? (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <Typography variant="h4" className={classes.title}>
                {mensagemInicio}
            </Typography>
            <Typography variant="h5" className={classes.subtitle}>
                Restam <div className={classes.segundos}>{segundosExecucao}</div> segundos <img className="img-timer" src={GreenEngine} alt={mensagemInicio} width={60}></img>
            </Typography>
        </div>
    ) : (
        mensagemFim && (
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <Typography variant="h4" className={classes.title}>
                    {mensagemFim} <CheckCircleOutlineRounded style={{ margin: 20, fontSize: "2.5rem" }} />
                </Typography>
            </div>
        )
    );
}
