import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CssBaseline } from "@material-ui/core";
import { getLista as getListaDispositivo } from "../dispositivo/dispositivoActions";

import Dashboard from "../dashboard/dashboard";
import ConfiguracaoFormDosadora from "../configuracaoDosadora/configuracaoFormDosadora";
import BottomMenu from "../common/components/bottomMenu";

class Main extends Component {
    componentDidMount() {
        this.props.getListaDispositivo(this.props.sessao.id || 0);
    }

    render() {
        if (this.props.aguardando) return null;
        return (
            <>
                <CssBaseline />
                {this.props.appModoTela === "growbox" ? <Dashboard /> : <ConfiguracaoFormDosadora />}
                <BottomMenu />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    sessao: state.auth.usuario,
    appModoTela: state.app.appModoTela,
    aguardando: state.dispositivo.aguardando,
});
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getListaDispositivo,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(Main);
