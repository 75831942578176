import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Grid } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";

import { initForm, getDados, salvar } from "./dataHoraActions";
import { getLista as getListaDispositivo } from "../dispositivo/dispositivoActions";
import { setModoTela } from "../main/mainActions";

class DataHoraForm extends Component {
    componentDidMount() {
        this.props.getDados();
    }
    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.dispositivoSelecionado) !== JSON.stringify(this.props.dispositivoSelecionado)) this.props.getDados();
    }

    getLocalStyles() {
        return {
            root: {
                padding: 100,
            },
            gridButton: {
                marginTop: 50,
            },
        };
    }

    render() {
        const style = this.getLocalStyles();

        return (
            <Grid container spacing={1} style={style.root} direction="column" justifyContent="center">
                <Grid container direction="row" justifyContent="center">
                    <MuiPickersUtilsProvider utils={LuxonUtils}>
                        <KeyboardDateTimePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy HH:mm"
                            margin="normal"
                            id="date-picker-inline"
                            label="Data"
                            ampm={false}
                            mask="__/__/____ __:__"
                            value={this.props.registro.dataHora}
                            onChange={(data) => {
                                this.props.initForm({
                                    ...this.props.registro,
                                    dataHora: data,
                                });
                            }}
                            KeyboardButtonProps={{
                                "aria-label": "Altera a data",
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid container style={style.gridButton} direction="row" justifyContent="center">
                    <Button style={{ marginRight: 5 }} variant="outlined" onClick={() => this.props.salvar(this.props.registro)} color="primary">
                        Salvar
                    </Button>
                    <Button style={{ marginLeft: 5 }} variant="outlined" onClick={() => this.props.setModoTela("dashboard")} color="secondary">
                        Voltar
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => ({
    sessao: state.auth.usuario,
    registro: state.dataHora.registro || {},
    dispositivoSelecionado: state.dispositivo.dispositivoSelecionado,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ initForm, getDados, getListaDispositivo, salvar, setModoTela }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DataHoraForm);
