import { makeStyles } from '@material-ui/core/styles';

export default () => {
	return makeStyles((theme) => ({
		icon: {
			marginRight: theme.spacing(2),
		},
		footer: {
			backgroundColor: theme.palette.background.paper,
			padding: theme.spacing(6),
		},
	}));
}
