import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, TextField, Grid, Typography, Slider, Switch, makeStyles, NumberInput } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardTimePicker } from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";

import { setModoTela } from "../main/mainActions";
import { initForm, getDados, salvar } from "./configuracaoActions";

const useStyles = makeStyles((theme) => ({
    indicator: {
        backgroundColor: "#a1f5ec", // Cor personalizada para o indicador
    },
    thumb: {
        height: 20,
        width: 20,
    },
    track: {
        height: 10,
    },
    rail: {
        height: 10,
    },
}));

const ConfiguracaoForm = (props) => {
    const classes = useStyles();

    useEffect(() => {
        props.getDados();
    }, []); // Executa apenas uma vez no montante

    const getLocalStyles = () => {
        return {
            root: {
                padding: 10,
                paddingBottom: 100,
            },
            gridInput: {
                marginTop: 16,
                paddingLeft: 16,
                paddingRight: 16,
            },
            gridButton: {
                marginTop: 20,
            },
        };
    };

    const style = getLocalStyles();

    return (
        <Grid container xs={12} sm={12} md={12} lg={12} spacing={1} style={style.root} direction="column" justifyContent="center" alignItems="center">
            <Grid container xs={12} sm={12} md={6} lg={6} style={style.gridInput} spacing={4}>
                <Grid item container xs={6} sm={6} md={6} lg={6} justifyContent="start" alignItems="center">
                    <MuiPickersUtilsProvider utils={LuxonUtils}>
                        <KeyboardTimePicker
                            disableToolbar
                            margin="normal"
                            id="time-picker"
                            label="Ligar"
                            ampm={false}
                            mask="__:__"
                            format="HH:mm"
                            value={props.registro.ligarHora}
                            onChange={(data) => {
                                props.initForm({
                                    ...props.registro,
                                    ligarHora: data,
                                });
                            }}
                            KeyboardButtonProps={{
                                "aria-label": "Altera a hora",
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>

                <Grid item container xs={6} sm={6} md={6} lg={6} justifyContent="start" alignItems="center">
                    <MuiPickersUtilsProvider utils={LuxonUtils}>
                        <KeyboardTimePicker
                            disableToolbar
                            margin="normal"
                            id="time-picker"
                            label="Desligar"
                            ampm={false}
                            mask="__:__"
                            format="HH:mm"
                            value={props.registro.desligarHora}
                            onChange={(data) => {
                                props.initForm({
                                    ...props.registro,
                                    desligarHora: data,
                                });
                            }}
                            KeyboardButtonProps={{
                                "aria-label": "Altera a hora",
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>

                <Grid container style={style.gridInput} direction="column" justifyContent="left">
                    <Typography id="discrete-slider" gutterBottom variant="body1">
                        Vega (dias)
                    </Typography>
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        value={props.registro.diasVega || 0}
                        onChange={(event) => {
                            let valor = parseInt((event.target.value || "0").replace(/\D/g, ""));

                            props.initForm({
                                ...props.registro,
                                diasVega: valor > 999 ? 999 : valor,
                            });
                        }}
                    />
                </Grid>

                <Grid container style={style.gridInput} direction="column" justifyContent="left">
                    <Typography id="discrete-slider" gutterBottom variant="body1">
                        Flora (dias)
                    </Typography>
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        value={props.registro.diasFlora || 0}
                        onChange={(event) => {
                            let valor = parseInt((event.target.value || "0").replace(/\D/g, ""));

                            props.initForm({
                                ...props.registro,
                                diasFlora: valor > 999 ? 999 : valor,
                            });
                        }}
                    />
                </Grid>

                <Grid container style={style.gridInput} direction="column" justifyContent="left">
                    <Typography id="discrete-slider" gutterBottom variant="body1">
                        Vega (%)
                    </Typography>
                    <Slider
                        classes={{
                            thumb: classes.thumb,
                            track: classes.track,
                            rail: classes.rail,
                        }}
                        defaultValue={props.registro.canalUm || 0}
                        aria-labelledby="input-slider"
                        valueLabelDisplay="auto"
                        marks={[{ value: props.registro.canalUm || 0, label: `${props.registro.canalUm || 0} %` }]}
                        value={props.registro.canalUm || 0}
                        onChange={(event, value) => {
                            props.initForm({
                                ...props.registro,
                                canalUm: value || 0,
                            });
                        }}
                        step={1}
                        min={0}
                        max={100}
                    />
                </Grid>

                <Grid container style={style.gridInput} direction="column" justifyContent="left">
                    <Typography id="discrete-slider" gutterBottom variant="body1">
                        Flora (%)
                    </Typography>
                    <Slider
                        classes={{
                            thumb: classes.thumb,
                            track: classes.track,
                            rail: classes.rail,
                        }}
                        defaultValue={props.registro.canalDois || 0}
                        aria-labelledby="input-slider"
                        valueLabelDisplay="auto"
                        marks={[{ value: props.registro.canalDois || 0, label: `${props.registro.canalDois || 0} %` }]}
                        value={props.registro.canalDois || 0}
                        onChange={(event, value) => {
                            props.initForm({
                                ...props.registro,
                                canalDois: value || 0,
                            });
                        }}
                        step={1}
                        min={0}
                        max={100}
                    />
                </Grid>
                <Grid container style={style.gridInput} direction="column" justifyContent="left">
                    <Typography id="discrete-slider" gutterBottom variant="body1">
                        Aviso de falta de água (dias)
                    </Typography>
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        value={props.registro.avisoAgua}
                        onChange={(event) => {
                            let valor = parseInt(event.target.value.replace(/\D/g, "")) || "";
                            props.initForm({
                                ...props.registro,
                                avisoAgua: valor ? (valor > 999 ? 999 : valor < 1 ? 1 : valor) : "",
                            });
                        }}
                    />
                </Grid>

                <Grid container style={style.gridInput} direction="column" justifyContent="left">
                    <Typography id="discrete-slider" gutterBottom variant="body1">
                        Velocidade máxima do ventilador em (°C)
                    </Typography>
                    <Slider
                        classes={{
                            thumb: classes.thumb,
                            track: classes.track,
                            rail: classes.rail,
                        }}
                        defaultValue={props.registro.tempVento || 0}
                        aria-labelledby="input-slider"
                        valueLabelDisplay="auto"
                        value={props.registro.tempVento || 0}
                        marks={[{ value: props.registro.tempVento || 0, label: `${props.registro.tempVento || 0} °C` }]}
                        onChange={(event, value) => {
                            props.initForm({
                                ...props.registro,
                                tempVento: value || 0,
                            });
                        }}
                        step={1}
                        min={0}
                        max={50}
                    />
                </Grid>

                <Grid container style={style.gridInput} direction="column" justifyContent="left">
                    <Typography id="discrete-slider" gutterBottom variant="body1">
                        Ventilação Constante
                    </Typography>
                    <Switch
                        color="primary"
                        checked={props.registro.ventilacaoConstante}
                        onChange={(event, value) => {
                            props.initForm({
                                ...props.registro,
                                ventilacaoConstante: value || false,
                            });
                        }}
                    />
                </Grid>

                <Grid container style={style.gridInput} direction="column" justifyContent="left">
                    <Typography id="discrete-slider" gutterBottom variant="body1">
                        Definir Início Hoje
                    </Typography>
                    <Switch
                        color="primary"
                        checked={props.registro.inicioHoje}
                        onChange={(event, value) => {
                            props.initForm({
                                ...props.registro,
                                inicioHoje: value || false,
                            });
                        }}
                    />
                </Grid>
            </Grid>

            <Grid container style={style.gridButton} direction="row" justifyContent="center">
                <Button style={{ marginRight: 5 }} variant="outlined" onClick={() => props.salvar(props.registro)} color="primary">
                    Salvar
                </Button>
                <Button style={{ marginLeft: 5 }} variant="outlined" onClick={() => this.props.setModoTela("dashboard")} color="secondary">
                    Voltar
                </Button>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    sessao: state.auth.usuario,
    registro: state.configuracao.registro || {},
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ setModoTela, initForm, getDados, salvar }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ConfiguracaoForm);
