import axios from "axios";
import { showTabs, selectTab } from "../common/tab/tabActions";
import consts from "../consts";
import { setErroAPI, setSuccess } from "../common/toast/toast";
import { reset as resetForm, initialize } from "redux-form";

export function setModoTela(modo, registro = {}) {
	return {
		type: "USUARIO_MODO_TELA",
		payload: {
			modo: modo,
			registro: registro,
		},
	};
}
export function initForm(registro = {}) {
	return [initialize("usuarioForm", registro)];
}

export function getLista() {
	const request = axios.get(`${consts.API_URL}/usuario?usuario=true`);
	return {
		type: "USUARIO_LISTADO",
		payload: request,
	};
}

export function salvar(registro) {
	return (dispatch, getState) => {
		if (!registro.id) {
			axios
				.post(`${consts.API_URL}/usuario`, {
					...registro,
					cpf: (registro.cpf || "").split(".").join("").split("-").join(""),
					termo_uso: true,
					administrador: true,
				})
				.then((resp) => {
					setSuccess("Operação Realizada com sucesso.");
					dispatch(getLista());
					dispatch(setModoTela("lista"));
				})
				.catch((e) => {
					setErroAPI(e);
				});
		} else {
			axios
				.put(`${consts.API_URL}/usuario`, {
					...registro,
					cpf: (registro.cpf || "").split(".").join("").split("-").join(""),
				})
				.then((resp) => {
					setSuccess("Operação Realizada com sucesso.");
					dispatch(getLista());
					dispatch(setModoTela("lista"));
				})
				.catch((e) => {
					setErroAPI(e);
				});
		}
	};
}

export function excluir(registro) {
	return (dispatch) => {
		axios
			.delete(`${consts.API_URL}/usuario?id=${registro.id}`)
			.then((resp) => {
				setSuccess("Operação Realizada com sucesso.");
				dispatch(getLista());
				dispatch(setModoTela("lista"));
			})
			.catch((e) => {
				setErroAPI(e);
			});
	};
}

export function alterarSenha(values) {
	return (dispatch, getState) => {
		axios
			.put(`${consts.API_URL}/usuario/alterarsenha`, { ...values, id: getState().auth.usuario.id })
			.then((resp) => {
				setSuccess("Operação Realizada com sucesso.");
				window.location.href = "/#";
			})
			.catch((e) => {
				setErroAPI(e);
			});
	};
}
