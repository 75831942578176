import axios from "axios";
import consts from "../consts";
import { setErroAPI, setSuccess } from "../common/toast/toast";
import { initialize } from "redux-form";

export function setModoTela(modo, registro = {}) {
    return {
        type: "DISPOSITIVO_MODO_TELA",
        payload: {
            modo: modo,
            registro: registro,
        },
    };
}
export function initForm(registro = {}) {
    return [initialize("dispositivoForm", registro)];
}

export function getLista(id_usuario) {
    return async (dispatch, getState) => {
        dispatch({
            type: "DISPOSITIVO_SET_AGUARDANDO",
            payload: true,
        });
        const request = await axios.get(`${consts.API_URL}/dispositivo?id_usuario=${id_usuario}`);
        const dispositivosUsuario = request.data || [];
        dispatch({
            type: "DISPOSITIVO_LISTADO",
            payload: { data: dispositivosUsuario },
        });
        if (!getState().dispositivo.dispositivoSelecionado) {
            const dispositivoSelecionado = dispositivosUsuario[0] || null;
            dispatch(setDispositivoSelecionado(dispositivoSelecionado));
        }
        dispatch({
            type: "DISPOSITIVO_SET_AGUARDANDO",
            payload: false,
        });
    };
}

export function setDispositivoSelecionado(dispositivo) {
    return (dispatch, getState) => {
        let modoTela = getState().app.modoTela;
        if (dispositivo && dispositivo.id_tipo == 3 && !modoTela.includes("Dosadora")) {
            modoTela = "configuracaoDosadora";
        } else if (dispositivo && dispositivo.id_tipo == 2 && !modoTela.includes("Growbox")) {
            modoTela = "dashboardGrowbox";
        }
        dispatch([
            {
                type: "DISPOSITIVO_SELECIONADO",
                payload: dispositivo,
            },
            {
                type: "SET_APP_MODO_TELA",
                payload: dispositivo && dispositivo.id_tipo == 3 ? "dosadora" : "growbox",
            },
            {
                type: "SET_MODO_TELA",
                payload: modoTela,
            },
        ]);
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
        if (!registro.id) {
            axios
                .post(`${consts.API_URL}/dispositivo`, {
                    ...registro,
                })
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(getLista());
                    dispatch(setModoTela("cadastro"));
                    dispatch(
                        initForm({
                            ...registro,
                            ...resp.data,
                        })
                    );
                })
                .catch((e) => {
                    setErroAPI(e);
                });
        } else {
            axios
                .put(`${consts.API_URL}/dispositivo`, {
                    ...registro,
                })
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(getLista());
                    dispatch(setModoTela("lista"));
                })
                .catch((e) => {
                    setErroAPI(e);
                });
        }
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        if (!getState().auth.usuario.administrador) {
            axios
                .put(`${consts.API_URL}/dispositivo`, {
                    ...registro,
                    id_usuario: null,
                    nome: null,
                })
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(setDispositivoSelecionado(null));
                    dispatch(getLista(registro.id_usuario));
                    window.location.reload();
                })
                .catch((e) => {
                    setErroAPI(e);
                });
        } else {
            axios
                .put(`${consts.API_URL}/dispositivo`, {
                    ...registro,
                    id_usuario_administrador: null,
                    nome_administrador: null,
                })
                .then((resp) => {
                    setSuccess("Operação Realizada com sucesso.");
                    dispatch(setDispositivoSelecionado(null));
                    dispatch(getLista(registro.id_usuario));
                    window.location.reload();
                })
                .catch((e) => {
                    setErroAPI(e);
                });
        }
    };
}
