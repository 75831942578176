import { createMuiTheme } from '@material-ui/core/styles';

export default () => {
	return createMuiTheme({
		palette: {
			background: {
				default: "#f3f3f3"
			},
			primary: {
				// light: será calculada com base em palette.primary.main,
				main: '#4db6ac',
				// dark: será calculada com base em palette.primary.main,
				contrastText: '#fff'
			},
			secondary: {
				main: '#0066ff'
			},
			// Usado por `getContrastText()` para maximizar o contraste entre
			// o plano de fundo e o texto.
			contrastThreshold: 3,
			// Usado pelas funções abaixo para mudança de uma cor de luminância por aproximadamente
			// dois índices dentro de sua paleta tonal.
			// Por exemplo, mude de Red 500 para Red 300 ou Red 700.
			tonalOffset: 0.2,
		}
	});
}
