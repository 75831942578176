import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AppBar, Tabs, Tab, Typography, Button, Grid, Slider, Switch, makeStyles, TextField } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardTimePicker } from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";

import { setModoTela } from "../main/mainActions";
import { initForm, getDados, salvar } from "./configuracaoDosadoraActions";

//outras telas
import ManualFormDosadora from "../manualDosadora/manualFormDosadora";
import DataHoraDosadoraForm from "../dataHoraDosadora/dataHoraDosadoraForm";
import ReservatorioFormDosadora from "../reservatorioDosadora/reservatorioFormDosadora";
import CalibragemFormDosadora from "../calibragemDosadora/calibragemFormDosadora";

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.primary.main,
        padding: 20,
    },
    indicator: {
        backgroundColor: "#a1f5ec", // Cor personalizada para o indicador
    },
    tab: {
        width: "80vw",
        outline: "none", // Remove o outline
        "&:focus": {
            outline: "none", // Remove o outline ao focar
        },
    },
    thumb: {
        height: 20,
        width: 20,
    },
    track: {
        height: 10,
    },
    rail: {
        height: 10,
    },
}));

const ConfiguracaoFormDosadora = (props) => {
    const classes = useStyles();

    useEffect(() => {
        props.getDados();
    }, []); // Executa apenas uma vez no montante

    useEffect(() => {
        if (props.modoTela === "configuracaoDosadora") {
            props.getDados();
        }
    }, [props.dispositivoSelecionado, props.modoTela]);

    function a11yProps(index) {
        return {
            id: `tab-${index}`,
            "aria-controls": `tab-panel-${index}`,
        };
    }

    const getLocalStyles = () => ({
        root: {
            padding: 10,
            paddingBottom: 100,
        },
        gridButton: {
            marginTop: 20,
        },
        gridInput: {
            padding: 20,
            marginTop: 20,
        },
    });

    const style = getLocalStyles();
    // console.log(JSON.stringify(props.registro, null, 4));
    return (
        <>
            {props.modoTela === "configuracaoDosadora" ? (
                <Grid container xs={12} sm={12} md={12} lg={12} spacing={1} style={style.root} direction="column" justifyContent="center" alignItems="center">
                    <Grid className={classes.tabs} item direction="row" justifyContent="left" xs={12} sm={12} md={10} lg={8}>
                        <Typography variant="h4" className={classes.title}>
                            Configuração
                        </Typography>
                        <AppBar position="static">
                            <Tabs
                                variant="fullWidth"
                                classes={{
                                    indicator: classes.indicator, // Aplicando a cor do indicador
                                }}
                                textColor="#FFF"
                                value={props.registro.bomba - 1}
                                onChange={(event, newValue) => {
                                    props.initForm({
                                        ...props.registro,
                                        bomba: newValue + 1,
                                    });
                                    props.getDados();
                                }}
                                aria-label="simple tabs example"
                            >
                                {Array.from({ length: props.quantidadeBombas }).map((_, i) => (
                                    <Tab className={classes.tab} key={i} label={`Bomba ${i + 1}`} {...a11yProps(i)} />
                                ))}
                            </Tabs>
                        </AppBar>
                        <Grid container style={style.gridInput} spacing={4}>
                            <Grid item container xs={6} sm={6} md={6} lg={6} justifyContent="center" alignItems="center">
                                <MuiPickersUtilsProvider utils={LuxonUtils}>
                                    <KeyboardTimePicker
                                        disableToolbar
                                        margin="normal"
                                        label="Início"
                                        ampm={false}
                                        format="HH:mm"
                                        value={props.registro.horarioInicio}
                                        onChange={(data) => {
                                            props.initForm({
                                                ...props.registro,
                                                horarioInicio: data,
                                            });
                                        }}
                                        disabled={props.registro.dosagemAutomatica} //quando na dosagem automatica não pode escolher nada
                                        KeyboardButtonProps={{
                                            "aria-label": "Altera a hora",
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>

                            <Grid item container xs={6} sm={6} md={6} lg={6} justifyContent="center" alignItems="center">
                                <MuiPickersUtilsProvider utils={LuxonUtils}>
                                    <KeyboardTimePicker
                                        disableToolbar
                                        margin="normal"
                                        label="Término"
                                        ampm={false}
                                        format="HH:mm"
                                        value={props.registro.horarioTermino}
                                        onChange={(data) => {
                                            props.initForm({
                                                ...props.registro,
                                                horarioTermino: data,
                                            });
                                        }}
                                        disabled={props.registro.dosagemAutomatica} //quando na dosagem automatica não pode escolher nada
                                        KeyboardButtonProps={{
                                            "aria-label": "Altera a hora",
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>

                        <Grid container style={style.gridInput} direction="row" spacing={4} className="space-between">
                            {[
                                { campo: "domingo", descricao: "Domingo" },
                                { campo: "segunda", descricao: "Segunda-feira" },
                                { campo: "terca", descricao: "Terça-feira" },
                                { campo: "quarta", descricao: "Quarta-feira" },
                                { campo: "quinta", descricao: "Quinta-feira" },
                                { campo: "sexta", descricao: "Sexta-feira" },
                                { campo: "sabado", descricao: "Sábado" },
                            ].map((item) => (
                                <Grid
                                    key={item.campo}
                                    item
                                    style={{ ...style.gridInput, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}
                                >
                                    <Typography gutterBottom variant="body1">
                                        {item.descricao}
                                    </Typography>
                                    <Switch
                                        color="primary"
                                        checked={props.registro[item.campo]}
                                        disabled={props.registro.dosagemAutomatica} //quando na dosagem automatica não pode escolher nada
                                        onChange={(event, value) => {
                                            props.initForm({
                                                ...props.registro,
                                                [item.campo]: value || false,
                                            });
                                        }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        <Grid container style={style.gridInput} direction="row" justifyContent="flex-start" alignItems="center">
                            <Typography gutterBottom variant="body1" style={{ marginRight: 10, width: "100%" }}>
                                Dose Diária (ml)
                            </Typography>
                            <Slider
                                classes={{
                                    thumb: classes.thumb,
                                    track: classes.track,
                                    rail: classes.rail,
                                }}
                                value={props.registro.doseDiaria}
                                valueLabelDisplay="auto"
                                disabled={props.registro.dosagemAutomatica} //quando na dosagem automatica não pode escolher nada
                                onChange={(event, value) => {
                                    props.initForm({
                                        ...props.registro,
                                        doseDiaria: value,
                                    });
                                }}
                                step={1}
                                min={1}
                                max={1000}
                                style={{ maxWidth: "calc(100% - 130px)", marginBottom: 10 }} // Allow Slider to grow and fill space
                            />
                            <div style={{ width: 130, minWidth: "fit-content", justifyContent: "center", display: "flex", alignItems: "flex-end" }}>
                                {/* Input para edição direta */}
                                <TextField
                                    type="number"
                                    value={props.registro.doseDiaria}
                                    onChange={(event) => {
                                        const value = parseFloat(event.target.value); // Captura o valor digitado
                                        props.initForm({
                                            ...props.registro,
                                            doseDiaria: value,
                                        });
                                    }}
                                    onBlur={(event) => {
                                        let value = parseFloat(event.target.value);

                                        // Verifica se o valor está fora dos limites
                                        if (isNaN(value) || value < 1) {
                                            value = 1; // Define como 1 se for inválido ou menor que 1
                                        } else if (value > 1000) {
                                            value = 1000; // Define como 200 se for maior que 200
                                        }

                                        props.initForm({
                                            ...props.registro,
                                            doseDiaria: value,
                                        });
                                    }}
                                    inputProps={{
                                        min: 1,
                                        max: 1000,
                                        step: 1,
                                    }}
                                />
                                <span style={{ marginLeft: 5, fontSize: "1.1rem" }}>ml</span>
                            </div>
                        </Grid>
                        <Grid container style={style.gridInput} direction="row" justifyContent="flex-start" alignItems="center">
                            <Typography gutterBottom variant="body1" style={{ marginRight: 10, width: "100%" }}>
                                Divisão da Dose
                            </Typography>
                            <Slider
                                classes={{
                                    thumb: classes.thumb,
                                    track: classes.track,
                                    rail: classes.rail,
                                }}
                                value={props.registro.divisao}
                                valueLabelDisplay="auto"
                                disabled={props.registro.dosagemAutomatica} //quando na dosagem automatica não pode escolher nada
                                onChange={(event, value) => {
                                    props.initForm({
                                        ...props.registro,
                                        divisao: value,
                                    });
                                }}
                                step={1}
                                min={1}
                                max={24}
                                style={{ maxWidth: "calc(100% - 130px)", marginBottom: 10 }} // Allow Slider to grow and fill space
                            />
                            <div style={{ width: 130, minWidth: "fit-content", justifyContent: "center", display: "flex", alignItems: "flex-end" }}>
                                {/* Input para edição direta */}
                                <TextField
                                    type="number"
                                    value={props.registro.divisao}
                                    onChange={(event) => {
                                        const value = parseFloat(event.target.value); // Captura o valor digitado
                                        props.initForm({
                                            ...props.registro,
                                            divisao: value,
                                        });
                                    }}
                                    onBlur={(event) => {
                                        let value = parseFloat(event.target.value);

                                        // Verifica se o valor está fora dos limites
                                        if (isNaN(value) || value < 1) {
                                            value = 1; // Define como 1 se for inválido ou menor que 1
                                        } else if (value > 24) {
                                            value = 24; // Define como 200 se for maior que 200
                                        }

                                        props.initForm({
                                            ...props.registro,
                                            divisao: value,
                                        });
                                    }}
                                    inputProps={{
                                        min: 1,
                                        max: 24,
                                        step: 1,
                                    }}
                                />
                                <span style={{ marginLeft: 5, fontSize: "1.1rem" }}> {props.registro.divisao > 1 ? " doses" : " dose"}</span>
                            </div>
                        </Grid>
                        <Grid container style={{ ...style.gridInput }} direction="column" justifyContent="flex-start" alignItems="left">
                            <Typography gutterBottom variant="body1">
                                Situação da Bomba
                            </Typography>
                            <div style={{ display: "flex", justifyContent: "start" }}>
                                <Switch
                                    color="primary"
                                    checked={props.registro.ativo}
                                    onChange={(event, value) => {
                                        props.initForm({
                                            ...props.registro,
                                            ativo: value || false,
                                        });
                                    }}
                                />
                                <span style={{ padding: "10px 10px" }}>{props.registro.ativo ? " (Ativada)" : " (Desativada)"}</span>
                            </div>
                        </Grid>
                        <Grid container style={style.gridInput} direction="row">
                            <Grid xs={5} xl={3} sm={3} md={3} lg={3} item style={style.gridInput} direction="column" justifyContent="flex-start" alignItems="left">
                                <Typography gutterBottom variant="body1">
                                    Dosagem Automática
                                </Typography>
                                <Switch
                                    style={{ width: "20%", minWidth: "fit-content" }}
                                    color="primary"
                                    checked={props.registro.dosagemAutomatica}
                                    onChange={(event, value) => {
                                        props.initForm({
                                            ...props.registro,
                                            dosagemAutomatica: value || false,
                                        });
                                    }}
                                />
                            </Grid>
                            {props.registro.dosagemAutomatica ? (
                                <Grid
                                    item
                                    xs={5}
                                    xl={3}
                                    sm={3}
                                    md={3}
                                    lg={3}
                                    style={{
                                        display: "flex",
                                        flexGrow: 1,
                                        minHeight: 60,
                                        margin: 25,
                                        backgroundColor: "#ddd",
                                        borderRadius: "0.3rem",
                                        overflow: "hidden",
                                    }}
                                >
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 50, backgroundColor: "#ccc" }}>
                                        <i className="fas fa-water" />
                                    </div>
                                    <div style={{ fontSize: "1rem", padding: 10 }}>
                                        <p style={{ margin: 0, fontWeight: "bold" }}>Umidade Atual</p>
                                        <p style={{ margin: 0 }}> {props.registro.umidadeAtual + " %"}</p>
                                    </div>
                                </Grid>
                            ) : null}
                        </Grid>
                        {props.registro.dosagemAutomatica ? (
                            <Grid container style={style.gridInput} direction="row" justifyContent="flex-start" alignItems="center">
                                <Typography gutterBottom variant="body1" style={{ marginRight: 10, width: "100%" }}>
                                    Umidade Mínima (%)
                                </Typography>
                                <Slider
                                    classes={{
                                        thumb: classes.thumb,
                                        track: classes.track,
                                        rail: classes.rail,
                                    }}
                                    value={props.registro.umidadeMinima}
                                    valueLabelDisplay="auto"
                                    onChange={(event, value) => {
                                        props.initForm({
                                            ...props.registro,
                                            umidadeMinima: value,
                                        });
                                    }}
                                    step={1}
                                    min={10}
                                    max={100}
                                    style={{ maxWidth: "80%", marginBottom: 10 }} // Allow Slider to grow and fill space
                                />
                                <span style={{ width: "20%", minWidth: "fit-content", textAlign: "center" }}>{props.registro.umidadeMinima + " %"}</span>
                            </Grid>
                        ) : null}
                        {/* Outros campos conforme o seu código */}
                        <Grid container style={style.gridButton} direction="row" justifyContent="center">
                            <Button style={{ marginRight: 5 }} variant="outlined" onClick={() => props.salvar(props.registro)} color="primary">
                                Salvar
                            </Button>
                            {/* <Button style={{ marginLeft: 5 }} variant="outlined" onClick={() => props.setModoTela("configuracaoDosadora")} color="secondary">
                                Voltar
                            </Button> */}
                        </Grid>
                    </Grid>
                </Grid>
            ) : props.modoTela === "manualDosadora" ? (
                <ManualFormDosadora />
            ) : props.modoTela === "dataHoraDosadora" ? (
                <DataHoraDosadoraForm />
            ) : props.modoTela === "reservatorioDosadora" ? (
                <ReservatorioFormDosadora />
            ) : props.modoTela === "calibragemDosadora" ? (
                <CalibragemFormDosadora />
            ) : null}
        </>
    );
};

const mapStateToProps = (state) => ({
    sessao: state.auth.usuario,
    modoTela: state.app.modoTela,
    registro: state.configuracaoDosadora.registro,
    quantidadeBombas: state.configuracaoDosadora.quantidadeBombas,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ setModoTela, initForm, getDados, salvar }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ConfiguracaoFormDosadora);
