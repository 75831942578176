import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './usuarioAlterarSenhaForm';

import { initForm, alterarSenha } from './usuarioActions';

class UsuarioAlterarSenha extends Component {

	state = {
		linhaSelecionada: null
	}

    render() {
        return (
            <div>
                <Content>
					<Form onSubmit={this.props.alterarSenha} />
                </Content>
            </div>
        )
    }

}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.usuario.modoTela
});
const mapDispatchToProps = dispatch => bindActionCreators({ initForm, alterarSenha }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UsuarioAlterarSenha);
