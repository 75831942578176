import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AppBar, Tabs, Tab, Typography, Button, Grid, Slider, makeStyles, TextField } from "@material-ui/core";

import { setModoTela } from "../main/mainActions";
import { initForm, getDados, salvar } from "./reservatorioDosadoraActions";
import CircularProgressChart from "../common/components/circularProgressChart";

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.primary.main,
        padding: 20,
    },
    indicator: {
        backgroundColor: "#a1f5ec", // Cor personalizada para o indicador
    },
    tab: {
        width: "80vw",
        outline: "none", // Remove o outline
        "&:focus": {
            outline: "none", // Remove o outline ao focar
        },
    },
    thumb: {
        height: 20,
        width: 20,
    },
    track: {
        height: 10,
    },
    rail: {
        height: 10,
    },
}));

const ReservatorioFormDosadora = (props) => {
    const classes = useStyles();

    useEffect(() => {
        props.getDados();
    }, []); // Executa apenas uma vez no montante

    useEffect(() => {
        props.getDados();
    }, [props.dispositivoSelecionado, props.modoTela]);

    function a11yProps(index) {
        return {
            id: `tab-${index}`,
            "aria-controls": `tab-panel-${index}`,
        };
    }

    const getLocalStyles = () => ({
        root: {
            padding: 10,
            paddingBottom: 100,
        },
        gridButton: {
            marginTop: 20,
        },
        gridInput: {
            padding: 20,
            marginTop: 20,
        },
    });

    const style = getLocalStyles();
    return (
        <Grid container xs={12} sm={12} md={12} lg={12} spacing={1} style={style.root} direction="column" justifyContent="center" alignItems="center">
            <Grid className={classes.tabs} item direction="row" justifyContent="left" xs={12} sm={12} md={10} lg={8}>
                <Typography variant="h4" className={classes.title}>
                    Reservatório
                </Typography>
                <AppBar position="static">
                    <Tabs
                        variant="fullWidth"
                        classes={{
                            indicator: classes.indicator, // Aplicando a cor do indicador
                        }}
                        textColor="#FFF"
                        value={props.registro.bomba - 1}
                        onChange={(event, newValue) => {
                            props.initForm({
                                ...props.registro,
                                bomba: newValue + 1,
                            });
                            props.getDados();
                        }}
                        aria-label="simple tabs example"
                    >
                        {Array.from({ length: props.quantidadeBombas }).map((_, i) => (
                            <Tab className={classes.tab} key={i} label={`Bomba ${i + 1}`} {...a11yProps(i)} />
                        ))}
                    </Tabs>
                </AppBar>
                <Typography variant="h4" className={classes.title}>
                    {`Bomba ${props.registro.bomba}`}
                </Typography>
                <Grid container style={style.gridInput} direction="row" justifyContent="space-around" alignItems="center">
                    <CircularProgressChart nome="Capacidade" percentual={((props.registro[`capacidade_bomba_${props.registro.bomba}`] || 0) * 100) / 10000} />
                    <CircularProgressChart
                        nome="Quantidade"
                        percentual={
                            props.registro[`capacidade_bomba_${props.registro.bomba}`] || 0
                                ? (
                                      ((props.registro[`quantidade_bomba_${props.registro.bomba}`] || 0) * 100) /
                                      (props.registro[`capacidade_bomba_${props.registro.bomba}`] || 0)
                                  ).toFixed(2)
                                : 0
                        }
                    />
                    <CircularProgressChart nome="Alerta" percentual={props.registro[`percentual_alerta_bomba_${props.registro.bomba}`] || 0} />
                </Grid>
                <Grid container style={style.gridInput} direction="row" justifyContent="flex-start" alignItems="center">
                    <Typography gutterBottom variant="body1" style={{ marginRight: 10, width: "100%" }}>
                        Capacidade (ml)
                    </Typography>
                    <Slider
                        classes={{
                            thumb: classes.thumb,
                            track: classes.track,
                            rail: classes.rail,
                        }}
                        value={props.registro[`capacidade_bomba_${props.registro.bomba}`] || 0}
                        valueLabelDisplay="auto"
                        disabled={false}
                        onChange={(event, value) => {
                            props.initForm({
                                ...props.registro,
                                [`capacidade_bomba_${props.registro.bomba}`]: value,
                                [`quantidade_bomba_${props.registro.bomba}`]:
                                    value > (props.registro[`quantidade_bomba_${props.registro.bomba}`] || 0)
                                        ? props.registro[`quantidade_bomba_${props.registro.bomba}`] || 0
                                        : value,
                            });
                        }}
                        step={1}
                        min={0}
                        max={10000}
                        style={{ maxWidth: "calc(100% - 130px)", marginBottom: 10 }} // Allow Slider to grow and fill space
                    />
                    <div style={{ width: 130, minWidth: "fit-content", justifyContent: "center", display: "flex", alignItems: "flex-end" }}>
                        {/* Input para edição direta */}
                        <TextField
                            type="number"
                            value={props.registro[`capacidade_bomba_${props.registro.bomba}`]}
                            onChange={(event) => {
                                const value = parseFloat(event.target.value); // Captura o valor digitado
                                props.initForm({
                                    ...props.registro,
                                    [`capacidade_bomba_${props.registro.bomba}`]: value,
                                    [`quantidade_bomba_${props.registro.bomba}`]:
                                        value > (props.registro[`quantidade_bomba_${props.registro.bomba}`] || 0)
                                            ? props.registro[`quantidade_bomba_${props.registro.bomba}`] || 0
                                            : value,
                                });
                            }}
                            onBlur={(event) => {
                                let value = parseFloat(event.target.value);

                                // Verifica se o valor está fora dos limites
                                if (isNaN(value) || value < 0) {
                                    value = 0; // Define como 1 se for inválido ou menor que 1
                                } else if (value > 10000) {
                                    value = 10000; // Define como 200 se for maior que 200
                                }

                                props.initForm({
                                    ...props.registro,
                                    [`capacidade_bomba_${props.registro.bomba}`]: value,
                                    [`quantidade_bomba_${props.registro.bomba}`]:
                                        value > (props.registro[`quantidade_bomba_${props.registro.bomba}`] || 0)
                                            ? props.registro[`quantidade_bomba_${props.registro.bomba}`] || 0
                                            : value,
                                });
                            }}
                            inputProps={{
                                min: 0,
                                max: 10000,
                                step: 1,
                            }}
                        />
                        <span style={{ marginLeft: 5, fontSize: "1.1rem" }}>ml</span>
                    </div>
                </Grid>
                <Grid container style={style.gridInput} direction="row" justifyContent="flex-start" alignItems="center">
                    <Typography gutterBottom variant="body1" style={{ marginRight: 10, width: "100%" }}>
                        Quantidade (ml)
                    </Typography>
                    <Slider
                        classes={{
                            thumb: classes.thumb,
                            track: classes.track,
                            rail: classes.rail,
                        }}
                        value={props.registro[`quantidade_bomba_${props.registro.bomba}`] || 0}
                        valueLabelDisplay="auto"
                        disabled={false}
                        onChange={(event, value) => {
                            props.initForm({
                                ...props.registro,
                                [`quantidade_bomba_${props.registro.bomba}`]:
                                    value > (props.registro[`capacidade_bomba_${props.registro.bomba}`] || 0)
                                        ? props.registro[`capacidade_bomba_${props.registro.bomba}`] || 0
                                        : value,
                            });
                        }}
                        step={1}
                        min={0}
                        max={10000}
                        style={{ maxWidth: "calc(100% - 130px)", marginBottom: 10 }} // Allow Slider to grow and fill space
                    />
                    <div style={{ width: 130, minWidth: "fit-content", justifyContent: "center", display: "flex", alignItems: "flex-end" }}>
                        {/* Input para edição direta */}
                        <TextField
                            type="number"
                            value={props.registro[`quantidade_bomba_${props.registro.bomba}`]}
                            onChange={(event) => {
                                const value = parseFloat(event.target.value); // Captura o valor digitado
                                props.initForm({
                                    ...props.registro,
                                    [`quantidade_bomba_${props.registro.bomba}`]:
                                        value > (props.registro[`capacidade_bomba_${props.registro.bomba}`] || 0)
                                            ? props.registro[`capacidade_bomba_${props.registro.bomba}`] || 0
                                            : value,
                                });
                            }}
                            onBlur={(event) => {
                                let value = parseFloat(event.target.value);

                                // Verifica se o valor está fora dos limites
                                if (isNaN(value) || value < 0) {
                                    value = 0; // Define como 1 se for inválido ou menor que 1
                                } else if (value > 10000) {
                                    value = 10000; // Define como 200 se for maior que 200
                                }

                                props.initForm({
                                    ...props.registro,
                                    [`quantidade_bomba_${props.registro.bomba}`]:
                                        value > (props.registro[`capacidade_bomba_${props.registro.bomba}`] || 0)
                                            ? props.registro[`capacidade_bomba_${props.registro.bomba}`] || 0
                                            : value,
                                });
                            }}
                            inputProps={{
                                min: 0,
                                max: 10000,
                                step: 1,
                            }}
                        />
                        <span style={{ marginLeft: 5, fontSize: "1.1rem" }}>ml</span>
                    </div>
                </Grid>
                <Grid container style={style.gridInput} direction="row" justifyContent="flex-start" alignItems="center">
                    <Typography gutterBottom variant="body1" style={{ marginRight: 10, width: "100%" }}>
                        Alerta de nível mínimo do reservatorio (%)
                    </Typography>
                    <Slider
                        classes={{
                            thumb: classes.thumb,
                            track: classes.track,
                            rail: classes.rail,
                        }}
                        value={props.registro[`percentual_alerta_bomba_${props.registro.bomba}`] || 0}
                        valueLabelDisplay="auto"
                        disabled={false}
                        onChange={(event, value) => {
                            props.initForm({
                                ...props.registro,
                                [`percentual_alerta_bomba_${props.registro.bomba}`]: value,
                            });
                        }}
                        step={1}
                        min={0}
                        max={99}
                        style={{ maxWidth: "calc(100% - 130px)", marginBottom: 10 }} // Allow Slider to grow and fill space
                    />
                    <div style={{ width: 130, minWidth: "fit-content", justifyContent: "center", display: "flex", alignItems: "flex-end" }}>
                        {/* Input para edição direta */}
                        <TextField
                            type="number"
                            value={props.registro[`percentual_alerta_bomba_${props.registro.bomba}`]}
                            onChange={(event) => {
                                const value = parseFloat(event.target.value); // Captura o valor digitado
                                props.initForm({
                                    ...props.registro,
                                    [`percentual_alerta_bomba_${props.registro.bomba}`]: value,
                                });
                            }}
                            onBlur={(event) => {
                                let value = parseFloat(event.target.value);

                                // Verifica se o valor está fora dos limites
                                if (isNaN(value) || value < 0) {
                                    value = 0; // Define como 1 se for inválido ou menor que 1
                                } else if (value > 99) {
                                    value = 99; // Define como 200 se for maior que 200
                                }

                                props.initForm({
                                    ...props.registro,
                                    [`percentual_alerta_bomba_${props.registro.bomba}`]: value,
                                });
                            }}
                            inputProps={{
                                min: 0,
                                max: 99,
                                step: 1,
                            }}
                        />
                        <span style={{ marginLeft: 5, fontSize: "1.1rem" }}>%</span>
                    </div>
                </Grid>

                <Grid container style={style.gridButton} direction="row" justifyContent="center">
                    <Button style={{ marginRight: 5 }} variant="outlined" onClick={() => props.salvar(props.registro)} color="primary">
                        Salvar
                    </Button>
                    <Button style={{ marginLeft: 5 }} variant="outlined" onClick={() => props.setModoTela("configuracaoDosadora")} color="secondary">
                        Voltar
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    sessao: state.auth.usuario,
    modoTela: state.app.modoTela,
    registro: state.reservatorioDosadora.registro,
    quantidadeBombas: state.reservatorioDosadora.quantidadeBombas,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ setModoTela, initForm, getDados, salvar }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ReservatorioFormDosadora);
