import axios from "axios";
import consts from "../consts";
import { setErroAPI } from "../common/toast/toast";

export function initForm(registro = {}) {
    return {
        type: "DOSADORA_CALIBRAGEM_SET_REGISTRO",
        payload: {
            registro,
        },
    };
}

export function getQuantidadeBombas() {
    return async (dispatch, getState) => {
        const resposta = await axios.get(`${consts.API_URL}/dosadora/bombas?serie=${getState().dispositivo.dispositivoSelecionado.serie}`);
        const { quantidade_bombas } = resposta.data;
        dispatch({
            type: "DOSADORA_CALIBRAGEM_SET_QUANTIDADE_BOMBAS",
            payload: quantidade_bombas || 3,
        });
    };
}

export function buscarUltimaCalibragem() {
    return async (dispatch, getState) => {
        dispatch(setAguardando(true));
        axios
            .get(`${consts.API_URL}/dosadora/calibragem?serie=${getState().dispositivo.dispositivoSelecionado.serie}`)
            .then((resp) => {
                const ultimaCalibragem = resp.data;
                // console.log(ultimaCalibragem);
                dispatch(setUltimaCalibragem(ultimaCalibragem));
            })
            .catch((e) => {
                console.log(e);
                setErroAPI(e);
            })
            .finally(() => dispatch(setAguardando(false)));
    };
}

export function salvar(registro) {
    return async (dispatch, getState) => {
        axios
            .post(`${consts.API_URL}/dosadora/calibragem?serie=${getState().dispositivo.dispositivoSelecionado.serie}`, {
                ...registro,
            })
            .then((resp) => {
                if (registro.dosar) {
                    dispatch(setTempoDosagem(60));
                } else {
                    dispatch(buscarUltimaCalibragem());
                }
            })
            .catch((e) => {
                console.log(e);
                setErroAPI(e);
            });
    };
}

export function setTempoDosagem(tempoDosagem) {
    return {
        type: "DOSADORA_CALIBRAGEM_SET_TEMPO_DOSAGEM",
        payload: tempoDosagem,
    };
}

export function setUltimaCalibragem(ultimaCalibragem) {
    return {
        type: "DOSADORA_CALIBRAGEM_SET_ULTIMA_CALIBRAGEM",
        payload: ultimaCalibragem,
    };
}

export function setAguardando(aguardando) {
    return {
        type: "DOSADORA_CALIBRAGEM_SET_AGUARDANDO",
        payload: aguardando,
    };
}