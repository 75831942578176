import React from "react";
import { Box, Typography, withStyles } from "@material-ui/core";

// Estilos customizados
const styles = (theme) => ({
    root: {
        display: "inline-block",
    },
    svg: {
        display: "block", // Keeps the progress centered
        transform: "rotate(-90deg)", // Inicia no topo (ângulo de 12h)
    },
    // Cor da parte não preenchida (trilha)
    circle: {
        stroke: "#cecece", // Vermelho para a parte não preenchida
    },
    // Cor da parte preenchida
    circleDeterminate: {
        stroke: "#4db6ac", // Verde para a parte preenchida
        transition: theme.transitions.create("stroke-dashoffset"),
    },
});

function CircularProgressChart({ classes, nome, percentual }) {
    const circumference = 2 * Math.PI * (50 - 5); // Definindo o perímetro do círculo

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <h5 style={{ textAlign: "center", color: "#4db6ac" }}>{nome}</h5>
            <Box position="relative" display="inline-flex">
                <svg className={classes.svg} viewBox="0 0 100 100" width={100} height={100}>
                    {/* Trilha (parte não preenchida) */}
                    <circle
                        className={classes.circle} // Cor da parte não preenchida
                        cx="50"
                        cy="50"
                        r="45"
                        fill="none"
                        strokeWidth="8"
                    />
                    {/* Parte preenchida (progresso) */}
                    <circle
                        className={classes.circleDeterminate} // Cor da parte preenchida
                        cx="50"
                        cy="50"
                        r="45"
                        fill="none"
                        strokeWidth="8"
                        strokeDasharray={circumference}
                        strokeDashoffset={circumference * ((100 - percentual) / 100)}
                    />
                </svg>
                <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
                    <Typography variant="h6" component="div" color="textSecondary">
                        {`${percentual}%`.replace(".", ",")}
                    </Typography>
                </Box>
            </Box>
        </div>
    );
}

export default withStyles(styles)(CircularProgressChart);
