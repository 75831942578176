const INITIAL_STATE = {
    appModoTela: "growbox",
    modoTela: "dashboard",
};

export default function mainReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case "SET_APP_MODO_TELA":
            return { ...state, appModoTela: action.payload };

        case "SET_MODO_TELA":
            return { ...state, modoTela: action.payload };

        default:
            return state;
    }
}
