import React from "react";
import { AppBar, Toolbar, Typography, Menu, MenuItem, IconButton, Divider, List, ListItem, ListItemText } from "@material-ui/core";
import {
    AccountCircle,
    ExitToApp as ExitToAppIcon,
    Build as BuildIcon,
    Settings as SettingsIcon,
    ArrowDropDown as ArrowDropDownIcon,
    Memory as MemoryIcon,
    Add as AddIcon,
    Delete as DeleteIcon,
} from "@material-ui/icons";
import { Route } from "react-router";
import { alpha, makeStyles } from "@material-ui/core/styles";
import imagemFolha from "../../assets/images/folha.png";

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto",
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    inputRoot: {
        color: "inherit",
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "20ch",
        },
    },
    button: {
        "&:focus": {
            outline: "none",
        },
    },
}));

export default (props) => {
    const classes = useStyles();
    const [perfilElement, setPerfilElement] = React.useState(null);
    const [controladoraElement, setControladoraElement] = React.useState(null);

    const isMenuPerfilAberto = Boolean(perfilElement);
    const abrirMenuPerfil = (event) => {
        setPerfilElement(event.currentTarget);
    };

    const isMenuControladoraAberto = Boolean(controladoraElement);
    const abrirMenuControladora = (event) => {
        setControladoraElement(event.currentTarget);
    };

    const menuId = "primary-search-account-menu";
    const renderMenuPerfil = (
        <Menu
            anchorEl={perfilElement}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={isMenuPerfilAberto}
            onClose={() => {
                setPerfilElement(null);
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingTop: 12,
                    paddingLeft: 12,
                    paddingRight: 12,
                }}
            >
                <div style={{ paddingRight: 8 }}>Nome:</div>
                <div style={{ fontWeight: "bold" }}>{props.sessao.nome}</div>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingLeft: 12,
                    paddingRight: 12,
                    paddingBottom: 12,
                }}
            >
                <div style={{ paddingRight: 8 }}>Email:</div>
                <div style={{ fontWeight: "bold" }}>{props.sessao.email}</div>
            </div>
            <Divider style={{ marginBottom: 12 }} />
            <MenuItem
                onClick={() => {
                    window.open("https://drive.google.com/drive/folders/12MoTUe9IkDwBBt35YeJOxz9P9XRB-Qq_?usp=sharing", "_blank");
                }}
            >
                <BuildIcon style={{ paddingRight: 8 }} /> Manual do usuário
            </MenuItem>
            <Divider style={{ marginTop: 12, marginBottom: 12 }} />
            <MenuItem
                onClick={() => {
                    window.location.href = "#/usuarioAlterarSenha";
                }}
            >
                <SettingsIcon style={{ paddingRight: 8 }} /> Alterar Senha
            </MenuItem>
            <Divider style={{ marginTop: 12, marginBottom: 12 }} />
            <MenuItem
                onClick={() => {
                    props.logout();
                    setPerfilElement(null);
                }}
            >
                <ExitToAppIcon style={{ paddingRight: 8 }} /> Sair
            </MenuItem>
        </Menu>
    );

    const renderMenuControladora = (
        <Menu
            anchorEl={controladoraElement}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={isMenuControladoraAberto}
            onClose={() => {
                setControladoraElement(null);
            }}
        >
            <List>
                {(props.dispositivos || []).map((item) => (
                    <ListItem key={item} button className={classes.menuItem}>
                        <ListItemText
                            primary={props.sessao.administrador ? item.nome_administrador : item.nome}
                            style={{ marginRight: 40 }}
                            onClick={() => {
                                props.setDispositivoSelecionado(item);
                            }}
                        />
                        {/*<IconButton edge="end" aria-label="comments">
							<EditIcon />
						</IconButton>*/}

                        <IconButton
                            edge="end"
                            aria-label="comments"
                            onClick={() => {
                                props.excluirDispositivo(item);
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </ListItem>
                ))}
            </List>
            <Divider style={{ marginBottom: 12 }} />
            <Route
                render={({ history }) => (
                    <MenuItem
                        onClick={() => {
                            history.push("/dispositivoUsuarioNovo");
                            setControladoraElement(null);
                        }}
                        style={{ justifyContent: "center" }}
                    >
                        <AddIcon /> Adicionar
                    </MenuItem>
                )}
            />
        </Menu>
    );

    return (
        <div className={classes.grow}>
            <AppBar position="fixed">
                <Toolbar>
                    {/*<IconButton
						edge="start"
						className={classes.menuButton}
						color="inherit"
						aria-label="open drawer" >
						<MenuIcon />
					</IconButton>*/}
                    <Typography variant="h6" noWrap>
                        <img src={imagemFolha} style={{ width: 30, paddingBottom: 10 }} alt="" /> Doled
                    </Typography>
                    <div className={classes.grow} />
                    <div>
                        <IconButton
                            className={classes.button}
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={abrirMenuControladora}
                            color="inherit"
                        >
                            <MemoryIcon />
                            {props.dispositivoSelecionado
                                ? props.sessao.administrador
                                    ? props.dispositivoSelecionado.nome_administrador
                                    : props.dispositivoSelecionado.nome
                                : "Selecione"}
                            <ArrowDropDownIcon />
                        </IconButton>
                        {/*<IconButton aria-label="show 17 new notifications" color="inherit">
							<Badge badgeContent={17} color="secondary">
								<NotificationsIcon />
							</Badge>
						</IconButton>*/}
                        <IconButton edge="end" aria-label="account of current user" aria-controls={menuId} aria-haspopup="true" onClick={abrirMenuPerfil} color="inherit">
                            <AccountCircle />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMenuPerfil}
            {renderMenuControladora}
        </div>
    );
};
