const INITIAL_STATE = { registro: {} };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case 'DISPOSITIVO_USUARIO_SET_REGISTRO':
            return { ...state, registro: action.payload.registro };

        default:
            return state;
    }
}
