import React, { Component } from "react";
import { HashRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ThemeProvider } from "@material-ui/styles";
import Header from "../common/template/header";
import Messages from "../common/msg/messages";

import getTheme from "../common/template/getTheme";

import { logout } from "../auth/authActions";
import {
  getLista as getListaDispositivo,
  excluir as excluirDispositivo,
  setDispositivoSelecionado,
} from "../dispositivo/dispositivoActions";

import Routes from "./routes";

class App extends Component {
  componentWillMount() {
    this.props.getListaDispositivo(this.props.sessao.id || 0);
  }

  render() {
    return (
      <ThemeProvider theme={getTheme()}>
        <HashRouter>
          <div>
            <Header
              sessao={this.props.sessao}
              logout={this.props.logout}
              dispositivos={this.props.dispositivos}
              dispositivoSelecionado={this.props.dispositivoSelecionado}
              excluirDispositivo={this.props.excluirDispositivo}
              setDispositivoSelecionado={this.props.setDispositivoSelecionado}
            />
            <Routes />
            <Messages />
          </div>
        </HashRouter>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  sessao: state.auth.usuario,
  dispositivos: state.dispositivo.lista,
  dispositivoSelecionado: state.dispositivo.dispositivoSelecionado,
  setDispositivoSelecionado: state.dispositivo.setDispositivoSelecionado,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout,
      getListaDispositivo,
      setDispositivoSelecionado,
      excluirDispositivo,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(App);
