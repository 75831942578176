const INITIAL_STATE = {
    quantidadeBombas: 3,
    registro: {
        bomba: 1,
        capacidade_bomba_1: 0,
        quantidade_bomba_1: 0,
        percentual_alerta_bomba_1: 0,
    },
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "DOSADORA_RESERVATORIO_SET_REGISTRO":
            return { ...state, registro: action.payload.registro };

        case "DOSADORA_RESERVATORIO_SET_QUANTIDADE_BOMBAS":
            return { ...state, quantidadeBombas: action.payload };

        default:
            return state;
    }
};
