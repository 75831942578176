import axios from "axios";
import consts from "../consts";
import { setErroAPI, setError, setSuccess } from "../common/toast/toast";

export function initForm(registro = {}) {
    return {
        type: "DOSADORA_RESERVATORIO_SET_REGISTRO",
        payload: {
            registro,
        },
    };
}

export function getQuantidadeBombas() {
    return async (dispatch, getState) => {
        const resposta = await axios.get(`${consts.API_URL}/dosadora/bombas?serie=${getState().dispositivo.dispositivoSelecionado.serie}`);
        const { quantidade_bombas } = resposta.data;
        dispatch({
            type: "DOSADORA_RESERVATORIO_SET_QUANTIDADE_BOMBAS",
            payload: quantidade_bombas || 3,
        });
    };
}

export function getDados() {
    return async (dispatch, getState) => {
        if (!getState().dispositivo.dispositivoSelecionado) return {};
        try {
            dispatch(getQuantidadeBombas());
            const bombaSelecionada = getState().reservatorioDosadora.registro.bomba;

            const resposta = await axios.get(`${consts.API_URL}/dosadora/reservatorio?serie=${getState().dispositivo.dispositivoSelecionado.serie}&bomba=${bombaSelecionada}`);
            if (!resposta.data) {
                setError("Não foi possível buscar dados da dosadora");
                return {};
            }

            const registro = {
                id: getState().dispositivo.dispositivoSelecionado.id,
                bomba: bombaSelecionada,
                [`capacidade_bomba_${bombaSelecionada}`]: resposta.data[`capacidade_bomba_${bombaSelecionada}`],
                [`quantidade_bomba_${bombaSelecionada}`]: resposta.data[`quantidade_bomba_${bombaSelecionada}`],
                [`percentual_alerta_bomba_${bombaSelecionada}`]: resposta.data[`percentual_alerta_bomba_${bombaSelecionada}`],
            };

            dispatch(initForm(registro));
        } catch (e) {
            console.log(e);
            setError("Controladora não respondeu");
        }
    };
    /*
        {
        capacidade_bomba_1: 0,
        quantidade_bomba_1: 0,
        percentual_alerta_bomba_1: 0
        }
	*/
}

export function salvar(registro) {
    return (dispatch, getState) => {
        console.log(registro);
        axios
            .post(`${consts.API_URL}/dosadora/reservatorio`, {
                ...registro,
            })
            .then((resp) => {
                console.log(resp);
                setSuccess("Reservatório atualizado com sucesso");
            })
            .catch((e) => {
                console.log(e);
                setErroAPI(e);
            });
    };
}
